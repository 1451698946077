import React from "react"
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import classNames from 'classnames'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
interface IProps {
    rank: number,
    move: number,
    type: 'up' | 'down',
    useRankCompare?: boolean
}


const ChartRank: React.FC<IProps> = ({rank, move, type, useRankCompare}) => {

    return (
        <div className={'chart_rank_wrapper'}>
            <span id={'rank_num'}>{rank}</span>
            {useRankCompare &&
                <>
                    {move === 0 ?
                        <HorizontalRuleIcon/>
                        :
                        <div className={classNames({
                            'rank_up': type === 'up',
                            'rank_down': type === 'down'
                        })}>
                            {type === 'up' ? <ArrowDropUpIcon className={'rank_up'}/> :
                                <ArrowDropDownIcon className={'rank_down'}/>}
                            <span>{Math.sqrt(Math.pow(move, 2))}</span>
                        </div>
                    }
                </>}
        </div>
    )
}

export default ChartRank