import {atom} from 'recoil'


export interface IPlayerMusic {
    musicNo: number
    title: string
    artist: string
    file: string
    coverImg?: string
}

interface IState {
    players: IPlayerMusic[],
    open: boolean,
    nowPlaying?: IPlayerMusic
}


export const musicPlayerListState = atom<IState>({
    key: 'musicPlayerListState',
    default: {
        players: [
            {
                musicNo: 21,
                artist: '',
                title: "Memories",
                file: "http://3.38.8.203/102c8ad4-105e-46d8-863d-bfb3119009ad.mp3",

            },
            {
                musicNo: 22,
                artist: '',
                title: "Creative Minds",
                file: "https://www.bensound.com/bensound-music/bensound-creativeminds.mp3"
            },
            {
                musicNo: 23,
                artist: '',
                title: "Acoustic Breeze",
                file: "https://www.bensound.com/bensound-music/bensound-acousticbreeze.mp3"
            },
            {
                musicNo: 24,
                artist: '',
                title: "Sunny",
                file: "https://www.bensound.com/bensound-music/bensound-sunny.mp3"
            },
            {
                musicNo: 25,
                artist: '',
                title: "Tenderness",
                file: "https://www.bensound.com/bensound-music/bensound-tenderness.mp3"
            },
            {
                musicNo: 26,
                artist: '',
                title: "Once Again",
                file: "https://www.bensound.com/bensound-music/bensound-onceagain.mp3"
            },
            {
                musicNo: 27,
                artist: '',
                title: "Sweet",
                file: "https://www.bensound.com/bensound-music/bensound-sweet.mp3"
            },
            {
                musicNo: 28,
                artist: '',
                title: "Love",
                file: "https://www.bensound.com/bensound-music/bensound-love.mp3"
            },
            {
                musicNo: 29,
                artist: '',
                title: "Piano Moment",
                file: "https://www.bensound.com/bensound-music/bensound-pianomoment.mp3"
            },
            {
                musicNo: 30,
                artist: '',
                title: "E.R.F",
                file: "https://www.bensound.com/bensound-music/bensound-erf.mp3"
            },
            {
                musicNo: 31,
                artist: '',
                title: "Dreams",
                file: "https://www.bensound.com/bensound-music/bensound-dreams.mp3"
            },
            {
                musicNo: 32,
                artist: '',
                title: "A Day To Remember",
                file:
                    "https://www.bensound.com/royalty-free-music/track/a-day-to-remember-wedding-music"
            },
            {
                musicNo: 33,
                artist: '',
                title: "Adventure",
                file: "https://www.bensound.com/bensound-music/bensound-adventure.mp3"
            },
            {
                musicNo: 34,
                artist: '',
                title: "Photo Album",
                file: "https://www.bensound.com/bensound-music/bensound-photoalbum.mp3"
            },
            {
                musicNo: 35,
                artist: '',
                title: "November",
                file: "https://www.bensound.com/bensound-music/bensound-november.mp3"
            }
        ],
        open: false
    }
})
