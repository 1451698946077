import {FieldValidation} from "../protocols";
import {ObjType} from "../../model/object-model";
import {InvalidFieldError} from "../errors";


export class PasswordValidation implements FieldValidation {
    constructor (readonly field: string) {}

    validate (input: ObjType): Error | null {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,16}/
        // const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
        return (!input[this.field] || passwordRegex.test(input[this.field])) ? null : new InvalidFieldError('8~16자 영문 대 소문자, 숫자, 특수문자를 사용하세요.')
    }
}
