import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import albumImage from '../../../assets/images/cover/small/1.jpg'
import ChartRank from "./ChartRank";
import {Music} from "../../../model/api/Music";
import {useRecoilState} from "recoil";
import {IPlayerMusic, musicPlayerListState} from "../../player/atoms";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {ReactEventHandler, SyntheticEvent} from "react";
import {playMusicCount} from "../../../helpers/music";

interface HeadCell {
    disablePadding: boolean;
    id: string;
    label: string;
    numeric: boolean;
    width?: number
}

const headCells: readonly HeadCell[] = [
    {
        id: 'rank',
        numeric: true,
        disablePadding: false,
        label: '순위',
        width: 10
    },
    {
        id: 'album',
        numeric: true,
        disablePadding: false,
        label: '앨범',
        width: 10
    },
    {
        id: 'title',
        numeric: false,
        disablePadding: false,
        label: '제목',
        width: 45
    },
    {
        id: 'nickname',
        numeric: true,
        disablePadding: false,
        label: '가수',
        width: 10
    },
    {
        id: 'like_cnt',
        numeric: true,
        disablePadding: false,
        label: '좋아요',
        width: 10
    },
    {
        id: 'play_cnt',
        numeric: true,
        disablePadding: false,
        label: '재생수',
        width: 10
    },
];

interface EnhancedTableProps {
    numSelected: number;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    rowCount: number;
    hideRank?: boolean
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const {onSelectAllClick, numSelected, rowCount, hideRank} = props;

    return (
        <TableHead>
            <TableRow>
                <TableCell style={{width: '5%'}}>
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.filter(cell => hideRank ? cell.id !== 'rank' : cell).map((headCell) => (
                    <TableCell
                        style={{width: `${headCell.width}%`}}
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
                <TableCell align={'center'} padding={'none'} width={80}>
                    재생
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

interface IProps {
    musics: Music[]
    hideRank?: boolean
    useRankCompare?: boolean
}

const TopMusicChart: React.FC<IProps> = ({musics, hideRank, useRankCompare}) => {
    const [selected, setSelected] = React.useState<number[]>([]);
    const [player, setPlayer] = useRecoilState(musicPlayerListState)

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = musics.map((n) => n.no);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: number) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleSelectMusicPlay = () => {
        const newMusics: IPlayerMusic[] = []
        selected.forEach(n => {
            const selIndex = musics.findIndex(music => music.no === n)
            if (selIndex > -1) {
                const selMusic = musics[selIndex]
                newMusics.push({
                    musicNo: selMusic.no,
                    title: selMusic.title,
                    artist: selMusic.nickname,
                    file: selMusic.file,
                    coverImg: selMusic.cover_img
                })
            }
        })
        setPlayer(old => ({...old, players: [...newMusics, ...old.players]}))
        setSelected([])
    }

    const handleListenMusic = async (music: Music) => {
        const newMusic: IPlayerMusic = {
            musicNo: music.no,
            title: music.title,
            artist: music.nickname,
            file: music.file,
            coverImg: music.cover_img
        }
        setPlayer(old => ({...old, players: [newMusic, ...old.players]}))
        await playMusicCount(music.no);
    }

    const isSelected = (name: number) => selected.indexOf(name) !== -1;

    const handleImgError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
        e.currentTarget.src = albumImage
    }

    return (
        <Box sx={{width: '100%'}}>

            <div className={'select_music'}>
                {selected.length > 0 &&
                    <button onClick={handleSelectMusicPlay}>선택한 음악 재생</button>
                }
            </div>
            <Paper sx={{width: '100%', mb: 2, backgroundColor: 'inherit', backgroundImage: 'none'}}>
                <TableContainer>
                    <Table
                        style={{tableLayout: 'fixed'}}
                        className={'top_chart_table'}
                        aria-labelledby="chart_table"
                        size={'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            onSelectAllClick={handleSelectAllClick}
                            rowCount={musics.length}
                            hideRank={hideRank}
                        />
                        <TableBody>
                            {musics.map((row, index) => {
                                const isItemSelected = isSelected(row.no);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        selected={isItemSelected}
                                        className={'top_chart_row'}
                                    >
                                        <TableCell
                                            onClick={(event) => handleClick(event, row.no)} padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        {!hideRank &&
                                            <TableCell align="center">
                                                <ChartRank rank={index + 1}
                                                           move={row.chart_old - row.chart_new}
                                                           type={row.chart_new < row.chart_old ? 'up' : 'down'}
                                                           useRankCompare={useRankCompare}/>
                                            </TableCell>
                                        }
                                        <TableCell
                                            align="center">
                                            <img width={80}
                                                 height={80}
                                                 src={row.cover_img ? row.cover_img : albumImage}
                                                 alt={'chart_album_image'}
                                                 onError={handleImgError}/>
                                        </TableCell>
                                        <TableCell align="left">{row.title}</TableCell>
                                        <TableCell align="center">{row.nickname}</TableCell>
                                        <TableCell align="center">{row.like_cnt}</TableCell>
                                        <TableCell align="center">{row.play_cnt}</TableCell>
                                        <TableCell align="center">
                                            <button className={'chart_play_btn'}
                                                    onClick={() => handleListenMusic(row)}>
                                                <PlayArrowIcon/>
                                            </button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}

                        </TableBody>
                    </Table>
                </TableContainer>

            </Paper>
        </Box>
    );
}
export default TopMusicChart