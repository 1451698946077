import {FieldValidation} from "../protocols";
import {ObjType} from "../../model/object-model";
import {InvalidFieldError} from "../errors";


export class CompareFieldsValidation implements FieldValidation {
    constructor(
        readonly field: string,
        private readonly fieldToCompare: string
    ) {
    }

    validate(input: ObjType): Error | null {
        return input[this.field] !== input[this.fieldToCompare] ? new InvalidFieldError('비밀번호가 일치하지 않습니다.') : null
    }
}
