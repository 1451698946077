import React, {useEffect, useState} from "react"
import Slider from "react-slick";
import NewReleaseChartItem from "./NewReleaseChartItem";
import {Music} from "../../model/api/Music";
import axios from "axios";
import {BaseResponse} from "../../model/api/BaseResponse";
import {ErrorResponse} from "../../helpers/error";


const SmallNewReleaseChart: React.FC = () => {
    const [newMusics, setNewMusics] = useState<Music[]>([])


    const getNewChart = async () => {
        try {
            const {data} = await axios.get(`/music/chart?type=new`)
            const result = data as BaseResponse

            if (result.success) {
                setNewMusics(result.data)
            }

        } catch (err: any) {
            ErrorResponse(err)
        }
    }

    useEffect(() => {
        getNewChart()
    }, [])

    const settings = {
        dots: false,
        infinite: true,
        speed: 600,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className={'small_new_release_chart_wrapper'}>
            <div className={'chart_header'}>
                <h3>최신음악 차트</h3>
                <hr/>
            </div>
            <div className={'chart_list'}>
                <Slider {...settings}>
                    {newMusics.slice(0, 14).map((music, index) => <NewReleaseChartItem key={index} music={music}/>)}
                </Slider>
            </div>
        </div>
    )
}

export default SmallNewReleaseChart