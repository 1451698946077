import React from "react"
import {useRecoilState} from "recoil";
import {writeNoticeFormState} from "./atoms";
import NavMain from "../nav/NavMain";
import Input from "./Input";
import NoticeQuillEditor from "./NoticeQuillEditor";
import {ErrorResponse} from "../../helpers/error";
import axios from "axios";
import {BaseResponse} from "../../model/api/BaseResponse";
import {notificationAlert} from "../../helpers/alert";


const WriteNoticePage: React.FC = () => {

    const [state, setState] = useRecoilState(writeNoticeFormState)


    const handleCreateNotice = async () => {
        try {
            if (state.title.length === 0) {
                notificationAlert('알림', '제목을 입력하여 주세요.')
            }
            if (state.content.length === 0) {
                notificationAlert('알림', '내용을 입력하여 주세요.')
            }
            const {data} = await axios.post('/admin/notice', state)
            const result = data as BaseResponse

            if (result.success) {
                notificationAlert('알림', '공지사항 작성이 완료되었습니다.')
                window.location.href = '/notice'
            }

        } catch (err: any) {
            ErrorResponse(err)
        }
    }

    return (
        <NavMain pageTitle={'공지사항 작성'}>
            <div className={'write_notice_page_wrapper'}>
                <Input type={'text'} name={'title'} label={'제목'} placeholder={'공지사항 제목을 입력하여 주세요.'}/>
                <div style={{backgroundColor: 'white'}}>
                    <NoticeQuillEditor
                        value={state.content}
                        onChange={(value: string) => setState(old => ({...old, content: value}))}
                    />
                </div>
                <div className={'notice_sub_btn'}>
                    <button onClick={handleCreateNotice}>등록</button>
                </div>
            </div>
        </NavMain>
    )
}


export default WriteNoticePage