import React from "react";
import NavMain from "../nav/NavMain";


const privacyTxt = '인디스트림(이하 ‘회사’)은(는) 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.\n' +
    '\n' +
    '제1조 개인정보의 처리목적\n' +
    '\n' +
    '가. 홈페이지 회원가입 및 관리\n' +
    '회원가입 의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정 이용 방지, 사고 보상, 각종 고지·통지, 고충 처리, 분쟁 해결 및 이를 위한 기록 보존 등을 위한 목적으로 개인정보를 처리합니다.\n' +
    '\n' +
    '나. 재화 또는 서비스 제공\n' +
    '후원금 제공 등 자산 이전에 관한 서비스 전반, 대금/수수료 결제·정산, 상속 및 양도 등의 목적으로 본인 인증하는 경우 개인정보를 처리합니다.\n' +
    '\n' +
    '다. 서비스 이용자의 본인 확인 및 회원정보 변경업무 처리\n' +
    '본인 인증, 연령 인증, 사용자 국적 확인 등의 목적으로 개인정보를 처리합니다.\n' +
    '\n' +
    '라. 이벤트 정보 안내 및 광고 활용\n' +
    '각종 이벤트 및 광고성 정보 제공, 신규 서비스 및 맞춤형 서비스 제공, 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 작성 등의 목적으로 개인정보를 처리합니다.\n' +
    '\n' +
    '마. 고충 처리\n' +
    '민원인의 신원 및 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보, 피해구제 등의 고충 처리를 목적으로 개인정보를 처리합니다.\n' +
    '\n' +
    '바. 법령상 의무이행 등을 위한 자료 제공\n' +
    '검찰/경찰 등 범죄 수사, 과세당국의 요청에 따른 과세자료 제공 등의 목적으로 개인정보를 처리합니다.\n' +
    '제2조 개인정보의 처리 및 보유\n' +
    '가. 회원가입 및 인증 과정에서 이용자의 동의를 거쳐 수집하는 개인정보의 필수항목\n' +
    '\n' +
    '나. 서비스 이용 및 처리 과정에서 자동으로 생성되어 수집되는 정보\n' +
    '\n' +
    '\n' +
    '라. 개인정보의 수집 방법\n' +
    '회사는 다음과 같은 방법으로 개인정보를 수집합니다.\n' +
    '① 최초 회원가입 또는 서비스 이용 시\n' +
    '② 홈페이지, 서면 양식, 이메일 주소, 전화, 팩스, 경품 행사 응모\n' +
    '③ 제휴사의 제공\n' +
    '④ 생성정보 수집 도구를 통한 수집\n' +
    '⑤ 본인 확인 등 이용자의 신청에 따른 제공\n' +
    '마. 보유 근거\n' +
    '회원의 동의 또는 근거 법률\n' +
    '바. 보유기간\n' +
    '① 이용자의 탈퇴 요청 및 개인정보 동의를 철회하는 때까지 보유합니다. 다만, 회사는 약관에 따른 회원의 부정이용기록 또는 부정이용이 의심되는 기록이 있는 경우에는 이용자의 탈퇴 요청 및 개인정보 동의의 철회에도 불구하고, 수집 시점으로부터 5년간 보관하고 파기합니다.\n' +
    '② 회사의 개인정보처리방침에도 불구하고, 아래와 같이 법령에 의하여 보관하여야 하는 정보는 법령이 정한 기간 동안 보관됩니다.\n' +
    '\n' +
    '제3조 개인정보처리의 위탁\n' +
    '회사는 개인정보 업무 처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있으며, 위탁받은 업체가 관계 법령을 위반하지 않도록 관리•감독하고 있습니다.\n' +
    '\n' +
    '제4조 개인정보의 제3자 제공\n' +
    '회사는 이용자의 사전 동의 없이 개인정보를 외부에 제공하지 않으며, 본 개인정보처리방침에서 정한 목적범위 내에서만 처리합니다. 그러나 ① 이용자가 제3자에 대한 개인정보 제공에 직접 사전 동의하거나 ② 관련 법령에 따라 회사에 개인정보 제출 의무가 발생하는 경우 또는 ③ 고객 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 고객 또는 제3자의 급박한 생명, 신체, 재산의 이익에 필요하다고 인정되는 경우 또는 ④ 통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 개인정보를 제공하는 경우에는 개인정보를 제3자에게 제공할 수 있습니다.\n' +
    '제5조 정보 주체의 권리 및 의무와 행사 방법\n' +
    '가. 이용자는 회사에 대해 언제든지 자신 및 미성년자(법정대리인만 해당)에 관한 개인정보에 대하여 다음 각 호의 권리를 행사할 수 있습니다. 단, 개인정보보호법 제35조 제4항, 제36조 제1항, 제37조 제2항 및 기타 관계 법령에 따라 이용자의 개인정보 열람·정정·삭제·처리정지 요구 등의 권리 행사가 제한될 수 있습니다.\n' +
    '① 개인정보 열람 요구\n' +
    '② 오류 등이 있을 경우 정정 요구\n' +
    '③ 삭제 요구\n' +
    '④ 처리정지 요구\n' +
    '나. 이용자의 권리 행사는 개인정보보호법 시행령 제41조 제1항에 따라 서면, 전자우편, FAX 등을 통하여서 할 수 있습니다\n' +
    '다. 본 권리 행사는 이용자의 법정대리인이나 위임받은 자 등 대리인을 통하여 할 수 있으며, 이 경우 개인정보보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하여야 합니다.\n' +
    '제6조 개인정보의 파기\n' +
    '가. 회사는 원칙적으로 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때는 지체없이 해당 개인정보를 파기합니다. 단, 이용자에게 별도의 동의를 받은 경우 또는 다른 법령에서 일정 기간 보존 의무를 부과하는 경우에는 해당 기간 동안 다른 개인 정보와 분리하여 보관합니다.\n' +
    '나. 정보통신망법에 따라 1년간 이용 기록이 없는 회원의 경우, 이용 중인 회원의 개인정보와 별도로 분리하여 보관합니다.\n' +
    '다. 개인정보 파기 절차는 보유기간의 경과, 처리목적 달성 등 파기 사유가 발생한 개인정보를 선정하고 시스템 자동 삭제 또는 개인정보보호 책임자 등의 승인을 받아 개인정보를 파기합니다.\n' +
    '라. 개인정보 파기 방법은 다음과 같습니다.\n' +
    '⓵ 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없도록 영구 삭제\n' +
    '⓶ 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각\n' +
    '제7조 개인정보의 안전한 보호 및 관리\n' +
    '가. 관리적 조치\n' +
    '회사는 개인정보 취급자를 업무 수행에 필요한 최소한으로 제한하며, 개인정보 취급자에 대한 교육 등 관리적 조치를 통해 개인정보보호의 중요성을 인식시키고 있습니다.\n' +
    '나. 기술적 조치\n' +
    '⓵ 회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 백업하고, 보안프로그램을 설치하고 주기적으로 갱신·점검하여 이용자들의 개인정보가 누출되거나 손상되지 않도록 방지하고 있습니다.\n' +
    '⓶ 회사는 이용자의 개인정보를 안전한 암호알고리즘에 의해 암호화하여 저장 및 관리합니다.\n' +
    '⓷ 회사는 개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 실시합니다.\n' +
    '⓸ 회사는 외부로부터 접근이 통제된 구역에 시스템을 설치하고, 출입통제 절차를 수립·운영합니다.\n' +
    '제8조 개인정보보호 책임자 및 담당 부서\n' +
    '가. 회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보 보호 책임자를 지정하고 있습니다.\n' +
    '\n' +
    '나. 회사는 가능한 기술적인 조치를 했음에도 불구하고, 첨단 기술을 이용한 해킹 등 네트워크 상의 위험에 의해 발생하는 예기치 못한 정보의 훼손에 관해서는 책임을 부담하지 않습니다.\n' +
    '제9조 권익침해 구제방법\n' +
    '개인정보 침해에 대한 피해구제, 상담 등이 필요할 경우 다음 기관에 문의하실 수 있습니다.\n' +
    '⓵ 개인정보 분쟁조정위원회\n' +
    '- 홈페이지: http://www.kopico.go.kr\n' +
    '- 전화: 1833-6972\n' +
    '⓶ 개인정보 침해신고센터\n' +
    '- 홈페이지: http://privacy.kisa.or.kr\n' +
    '- 전화: (국번없이) 118\n' +
    '⓷ 대검찰청 첨단범죄수사과\n' +
    '- 홈페이지: http://spo.go.kr\n' +
    '- 전화: 02-3480-2000\n' +
    '⓸ 경찰청 사이버안전국\n' +
    '- 홈페이지: http://cyberbureau.police.go.kr\n' +
    '- 전화: (국번없이) 182\n' +
    '제10조 개인정보 자동수집 장치의 설치와 운영 및 그 거부에 관한 사항\n' +
    '회사는 이용자의 서비스 편의를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다. 쿠키는 웹사이트가 고객의 컴퓨터 웹브라우저에 전송하는 소량의 정보입니다. 이용자의 웹 브라우저에서 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나 모든 쿠키의 저장을 거부하는 등의 옵션을 선택하실 수 있습니다. 단, 고객이 쿠키의 저장을 거부하는 옵션을 선택하시는 경우에는 서비스 이용에 불편이 야기될 수 있습니다. 이용자는 쿠키 설치에 대한 선택권이 있으며, 언제든지 이러한 쿠키의 저장을 거부하거나 삭제할 수 있습니다.\n' +
    '제11조 외부 링크 사이트에 대한 책임\n' +
    '회사는 이용자에게 다른 외부 사이트로 연결되는 링크를 제공할 수 있습니다. 이 경우 회사는 외부 사이트에 대한 통제권이 없으므로 이용자가 외부 사이트로부터 제공받는 서비스나 자료의 유용성, 진실성, 적법성을 보장할 수 없으며, 링크된 외부 사이트의 개인정보처리방침은 회사와 무관하므로 해당 외부 사이트의 정책을 확인하시기 바랍니다.\n' +
    '제12조 개인정보처리방침 변경\n' +
    '회사가 개인정보처리방침을 변경하는 경우에는 변경 및 시행의 시기, 변경된 내용 등을 시행 전에 미리 공개합니다.\n' +
    '제13조 부칙\n' +
    '본 개인정보처리방침은 2022년 12월 1일부터 적용됩니다.'

const PrivacyPage: React.FC = () => {

    return (
        <NavMain pageTitle={'인디스트림 개인정보 처리방침'}>
            <div className={'privacy_wrapper'}>
                {privacyTxt.split('\n')
                    .map((line, index) => {
                        return (
                            <p key={index}>{line}<br/></p>
                        )
                    })}
            </div>
        </NavMain>
    )
}

export default PrivacyPage;