import React from 'react'
import {useRecoilState} from 'recoil'
import {InputBase} from "../../common";
import {authPhoneFormState} from "./atoms";
import {registerFormState} from "../../auth/register/atoms";

type Props = {
    type: string
    name: string
    label: string
    placeholder: string
    disabled?: boolean
}

const Input: React.FC<Props> = ({type, name, placeholder, label, disabled}: Props) => {
    const [state, setState] = useRecoilState(registerFormState)

    return (
        <div className={'label_input_wrapper'}>
            <label>
                {label}
            </label>
            <InputBase
                className={'auth_input'}
                disabled={disabled}
                type={type}
                name={name}
                placeholder={placeholder}
                state={state}
                setState={setState}
            />
        </div>
    )
}

export default Input
