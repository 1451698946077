import {atom} from 'recoil'
import {Album} from "../../model/api/Album";
import {Music} from "../../model/api/Music";


interface IState {
    song: Music
}


export const songDetailState = atom<IState>({
    key: 'songDetailState',
    default: {
        song: {
            no: 0,
            file: '',
            artist_no: 0,
            title: '',
            lyricist: '',
            songwriter: '',
            arranger: '',
            genre: '',
            mood: '',
            participation: '',
            description: '',
            lyrics: '',
            tags: [],
            create_time: new Date(),
            nickname: '',
            like_cnt: 0,
            play_cnt: 0,
            isLike: false,
            pass: false,
            chart_old: 0,
            chart_new: 0
        }
    }
})
