import {FieldValidation} from "../protocols";
import {ObjType} from "../../model/object-model";
import {InvalidFieldError} from "../errors";


export class EmailValidation implements FieldValidation {
  constructor (readonly field: string) {}

  validate (input: ObjType): Error | null {
    const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
    return (!input[this.field] || emailRegex.test(input[this.field])) ? null : new InvalidFieldError('이메일 형식이 올바르지 않습니다.')
  }
}
