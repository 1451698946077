import React from 'react'
import ReactDOM from 'react-dom/client'
import './assets/scss/index.scss'
import Router from "./router/Router"
import {ThemeProvider, createTheme} from '@mui/material/styles'
import axios from "axios"
import {isLogin} from "./helpers/auth";
import {notificationAlert} from "./helpers/alert";

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.REACT_APP_API_ADDRESS

axios.interceptors.request.use(
    request => {
        if (!!localStorage.getItem('_indieTk')) {
            if (request.headers) {
                request.headers["Authorization"] = `Bearer ${localStorage.getItem('_indieTk')}`
                request.headers["accept"] = "application/json"
            }
        }
        return request
    },
    err => Promise.reject(err)
)

axios.interceptors.response.use((res) => {
    return res
}, function (error) {
    if (error.response){
    if (401 === error.response.status) {
        if (isLogin()) {
            notificationAlert('알림', '세션이 만료되었습니다.')
            setTimeout(() => {
                localStorage.removeItem('_uid');
                localStorage.removeItem('_indieTk');
                window.location.replace('/');
            }, 1000)
        }else{
            return Promise.reject(error);
        }
    } else {
        return Promise.reject(error);
    }
    }else {
        return Promise.reject(error);
    }
});


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});


root.render(
    <React.StrictMode>
        <ThemeProvider theme={darkTheme}>
            <Router/>
        </ThemeProvider>
    </React.StrictMode>
);


