import React, {SyntheticEvent, useEffect} from "react"
import NavMain from "../../nav/NavMain";
import songBaseThumbnail from "../../../assets/images/cover/large/5.jpg";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {useParams} from "react-router-dom";
import {useRecoilState, useRecoilValue} from "recoil";
import axios from "axios";
import {BaseResponse} from "../../../model/api/BaseResponse";
import {ErrorResponse} from "../../../helpers/error";
import {songDetailState} from "../atoms";
import dayjs from "dayjs";
import {notificationAlert} from "../../../helpers/alert";
import FavoriteIcon from '@mui/icons-material/Favorite';
import {profileState} from "../../nav/atoms";
import Swal from "sweetalert2";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {IPlayerMusic, musicPlayerListState} from "../../player/atoms";
import {Music} from "../../../model/api/Music";
import {playMusicCount} from "../../../helpers/music";

const SongDetailPage: React.FC = () => {

    let {no} = useParams()
    const [state, setState] = useRecoilState(songDetailState)
    const [player, setPlayer] = useRecoilState(musicPlayerListState)

    const userInfo = useRecoilValue(profileState)
    useEffect(() => {
        handleGetSongInfo(no)
    }, [no, userInfo.no])

    const handleGetSongInfo = async (songNo: string | undefined) => {
        try {
            if (!songNo) {
                return;
            }
            const {data} = await axios.get(`/music/${songNo}`)
            const result = data as BaseResponse

            if (result.success) {
                setState(old => ({...old, song: {...result.data}}))
            }

        } catch (err: any) {
            ErrorResponse(err)
        }
    }

    const handleClickLikeSong = async () => {
        try {

            if (!no) {
                notificationAlert('알림', '잘못된 음악정보 입니다.')
                return
            }

            const {data} = await axios.post(`/music/${no}/like`, {})
            const result = data as BaseResponse
            if (result.success) {
                if (state.song.isLike) {
                    notificationAlert('알림', '좋아요가 취소되었습니다.')
                } else {
                    notificationAlert('알림', '좋아요가 반영되었습니다.')
                }
                setState(old => ({...old, song: {...old.song, isLike: !old.song.isLike}}))
            }

        } catch (err: any) {
            ErrorResponse(err)
        }
    }

    const handleDeleteSong = (songNo: number) => {
        Swal.fire({
            title: '정말 곡을 삭제하시겠습니까?',
            text: '곡 삭제시 해당되는 모든 데이터가 지워집니다.',
            showCancelButton: true,
            cancelButtonText: '취소',
            confirmButtonText: '삭제',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                return await axios.delete(`/music/${songNo}`)
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(result => {
            if (result) {
                if (result.isConfirmed && result.value) {
                    Swal.fire({
                        title: '곡 삭제가 완료되었습니다.',
                        confirmButtonText: '닫기',
                        preConfirm: async () => {
                            return window.location.href = '/myStudio'
                        },
                        allowOutsideClick: false
                    })

                }
            }
        }).catch(err => {
            ErrorResponse(err)
        })

    }

    const handleImgError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
        e.currentTarget.src = songBaseThumbnail
    }

    const handleListenMusic = async (music: Music) => {
        const newMusic: IPlayerMusic = {
            musicNo: music.no,
            title: music.title,
            artist: music.nickname,
            file: music.file,
            coverImg: music.cover_img
        };
        setPlayer(old => ({...old, players: [newMusic, ...old.players]}));
        await playMusicCount(music.no);
    }

    return (
        <NavMain pageTitle={'곡 정보'}>
            <div className={'song_detail_page_wrapper'}>
                <div className={'detail_info'}>
                    <div className={'thumbnail'}>
                        <img src={state.song.cover_img ? state.song.cover_img : songBaseThumbnail}
                             alt={'thumbnail'}
                             onError={handleImgError}/>
                    </div>
                    <div className={'description'}>
                        <h2 className={'title'}>{state.song.title}</h2>
                        <p className={'artist'}>{state.song.nickname}</p>
                        <p className={'genre'}>{state.song.genre}</p>
                        <small>{dayjs(state.song.create_time).format('YYYY년 MM월 DD일')}</small>
                        <div className={'btn_wrapper'}>
                            <button className={'icon_btn'} onClick={() => handleListenMusic(state.song)}>
                                <PlayArrowIcon/></button>
                            <button className={'icon_btn'}
                                    onClick={handleClickLikeSong}
                            >
                                {state.song.isLike ?
                                    <FavoriteIcon style={{color: 'red'}}/>
                                    :
                                    <FavoriteBorderIcon/>
                                }

                            </button>
                        </div>
                    </div>
                    {userInfo.no === state.song.artist_no &&
                        <div className={'delete_btn_wrapper'}>
                            <button onClick={() => handleDeleteSong(state.song.no)}>곡 삭제</button>
                        </div>}
                </div>
                <div className={'introduce_song_wrapper'}>
                    <h5 className={'title'}>상세정보</h5>

                    <div className={'song_detail_info'}>
                        <dl className="list">
                            <dt>발매일</dt>
                            <dd>{dayjs(state.song.create_time).format('YYYY.MM.DD')}</dd>
                            <dt>장르</dt>
                            <dd>{state.song.genre}</dd>
                            <dt>작곡가</dt>
                            <dd>{state.song.songwriter}</dd>
                            <dt>작사가</dt>
                            <dd>{state.song.lyricist}</dd>
                        </dl>
                    </div>

                    <p>
                        "처음 네가 떠나가는<br/>
                        오늘 마지막이 될 기념일"<br/>
                        <br/>
                        추억을 '말하는' 벤<br/>
                        말하는 프로젝트 네 번째,<br/>
                        <br/>
                        [Credit]<br/>
                        Composed by : 한경수, 이도형(AUG)<br/>
                        Lyrics by : 한경수, 이도형(AUG)<br/>
                        Arranged by : 이도형(AUG), 임준식(AUG)<br/>
                        Piano : 이도형(AUG)<br/>
                        Guitar : 정수완<br/>
                        Bass : 이도형(AUG)<br/>
                        Drum : 이도형(AUG)<br/>
                        Strings : 융스트링<br/>
                        Strings Arrange & Conduct : 이도형(AUG)<br/>
                        Percussion : 한경수<br/>
                        <br/>
                        Recorded by 손아현 @ MAJOR9 Studio<br/>
                        Audio Edited by 김선아 @ MAJOR9 Studio<br/>
                        Mixed by 정기홍 @서울스튜디오<br/>
                        Mastered by 권남우 @ 821 Sound Mastering<br/>
                    </p>
                </div>
            </div>
        </NavMain>
    )
}

export default SongDetailPage