import {atom} from 'recoil'
import {makeSignUpValidation} from "../../../factories/validation/signup-validation-factory";
import {ObjType} from "../../../model/object-model";


interface IState {
    email: string,
    password: string,
    passwordConfirm: string,
    nickName: string,
    phone: string
    type: 'normal',
    isAuthPhone: boolean,
    isSuccessAuthPhone: boolean,
    userAuthNum: string,
    authNum: string
}

interface Validation {
    validate: (fieldName: string, input: ObjType) => string
}

interface IValidation {
    validation: Validation
    isFormInvalid: boolean
    nickNameError: string
    emailError: string
    passwordError: string
    passwordConfirmError: string
}

export const registerValidationState = atom<IValidation>({
    key: 'registerValidationState',
    default: {
        validation: makeSignUpValidation(),
        isFormInvalid: false,
        nickNameError: '',
        emailError: '',
        passwordError: '',
        passwordConfirmError: '',
    }
})

export const registerFormState = atom<IState>({
    key: 'registerFormState',
    default: {
        email: '',
        password: '',
        passwordConfirm: '',
        nickName: '',
        phone: '',
        type: 'normal',
        isAuthPhone: false,
        isSuccessAuthPhone: false,
        userAuthNum: '',
        authNum: ''
    }
})
