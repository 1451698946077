import {atom} from 'recoil'


interface Form {
    title: string
    genre: string[]
    images: File[]
}


export const createAlbumFormState = atom<Form>({
    key: 'createAlbumFormState',
    default: {
        title: '',
        genre: [],
        images: []
    }
})
