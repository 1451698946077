import {atom} from 'recoil'


interface IState {
    email: string,
    password: string
}


export const loginFormState = atom<IState>({
    key: 'loginFormState',
    default: {
        email: '',
        password: ''
    }
})
