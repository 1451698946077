import HomeIcon from '@mui/icons-material/Home';
import {ReactNode} from "react";
import MusicNoteIcon from '@mui/icons-material/MusicNote';


interface NavItem {
    title: string,
    link: string,
    icon: ReactNode
}

export interface INavBarItems {
    title: string,
    items: NavItem[]
}


export const navbarItems: INavBarItems[] = [
    {
        title: 'BROWSE MUSIC',
        items: [
            {
                title: 'Home',
                link: '/',
                icon: <HomeIcon/>
            },
            {
                title: '장르음악',
                link: '/genre',
                icon: <MusicNoteIcon/>
            },
            {
                title: '최신음악',
                link: '/newMusic',
                icon: <MusicNoteIcon/>
            },
            // {
            //     title: '아티스트',
            //     link: '/artist',
            //     icon: <MusicNoteIcon/>
            // },
            {
                title: '실시간 차트',
                link: '/topChart',
                icon: <MusicNoteIcon/>
            }
        ]
    },
    {
        title: 'MUSIC EVENTS',
        items: [
            {
                title: '공지사항',
                link: '/notice',
                icon: <HomeIcon/>
            }
        ]
    },
    {
        title: '',
        items: [
            {
                title: '음악 업로드',
                link: '/myStudio?type=upload',
                icon: <HomeIcon/>
            }
        ]
    }


]
