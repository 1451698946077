import React, {useEffect, useState} from "react"
import AuthBackground from "../AuthBackground";
import Input from "./Input";
import AuthPhone from "./AuthPhone";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import {useRecoilState, useRecoilValue} from "recoil";
import {registerFormState, registerValidationState} from "./atoms";
import {BaseResponse} from "../../../model/api/BaseResponse";
import {ErrorResponse} from "../../../helpers/error";
import {notificationAlert} from "../../../helpers/alert";
import Checkbox from "@mui/material/Checkbox";
import {FormControlLabel, FormGroup} from "@mui/material";


const RegisterPage: React.FC = () => {
    const navigator = useNavigate()
    const [state, setState] = useRecoilState(registerFormState)
    const [validationState, setValidationState] = useRecoilState(registerValidationState)
    const [agreeTerm, setAgreeTerm] = useState<boolean>(false);
    const [agreePrivacy, setAgreePrivacy] = useState<boolean>(false);

    useEffect(() => validate('email'), [state.email])
    useEffect(() => validate('password'), [state.password])
    useEffect(() => validate('nickName'), [state.nickName])
    useEffect(() => validate('passwordConfirm'), [state.passwordConfirm])

    const validate = (field: string): void => {
        const {email, password, passwordConfirm, nickName} = state
        const formData = {email, password, passwordConfirm, nickName}
        setValidationState(old => ({...old, [`${field}Error`]: validationState.validation.validate(field, formData)}))
        setValidationState(old => ({
            ...old,
            isFormInvalid: !!old.nickNameError || !!old.emailError || !!old.passwordError || !!old.passwordConfirmError
        }))
    }


    const handleRegister = async () => {
        try {

            const {data} = await axios.post('/auth/local/new', {
                email: state.email,
                password: state.password,
                phone: state.phone,
                nickname: state.nickName,
                type: state.type
            })
            const result = data as BaseResponse

            if (result.success) {
                notificationAlert('알림', '회원가입이 완료되었습니다.')
                setTimeout(() => {
                    navigator('/login')
                }, 1000)

            }
        } catch (err: any) {
            ErrorResponse(err)
        }

    }

    return (
        <AuthBackground>
            <div className={'auth_title_wrapper'}>
                <h2>회원가입</h2>
            </div>
            <div className={'register_page_wrapper'}>
                <div className={'register_form_wrapper'}>
                    <Input type={'text'} name={'email'} label={'이메일'} placeholder={'이메일'}
                           errorMsg={validationState.emailError}/>
                    <Input type={'password'} name={'password'} label={'비밀번호'} placeholder={'비밀번호'}
                           errorMsg={validationState.passwordError}/>
                    <Input type={'password'} name={'passwordConfirm'} label={'비밀번호 확인'} placeholder={'비밀번호 확인'}
                           errorMsg={validationState.passwordConfirmError}/>
                    <Input type={'text'} name={'nickName'} label={'닉네임'}
                           maxLength={10}
                           placeholder={'닉네임'}
                           errorMsg={validationState.nickNameError}/>
                    <AuthPhone/>
                </div>
                <div style={{padding: '1.2rem 0'}}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox onChange={e => setAgreeTerm(e.target.checked)}/>}
                            label="서비스 이용약관 동의"/>
                        <FormControlLabel
                            control={<Checkbox onChange={e => setAgreePrivacy(e.target.checked)}/>}
                            label="개인정보 수집 및 이용동의 "/>
                    </FormGroup>
                </div>

                <div className={'register_btn_wrapper'}>
                    <button
                        disabled={validationState.isFormInvalid || !state.isSuccessAuthPhone || !(agreeTerm && agreePrivacy)}
                        onClick={handleRegister}>가입하기
                    </button>
                </div>
            </div>
            <ul className={'auth_find_wrapper'}>
                <li>
                    <Link to={'/terms'}>
                        이용약관
                    </Link>
                </li>
                <li>
                    <Link to={'/privacy'}>
                        개인정보처리방침
                    </Link>
                </li>
                {/*<li>*/}
                {/*    <Link to={'/'}>*/}
                {/*        문의하기*/}
                {/*    </Link>*/}
                {/*</li>*/}
            </ul>
        </AuthBackground>
    )
}


export default RegisterPage