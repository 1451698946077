import React from "react"
import {Col, Row} from "react-bootstrap";
import Input from "./Input";
import {genres} from "../../genreChart/Constant";
import {useRecoilState, useRecoilValue} from "recoil";
import {myStudioState, songUploadFormState} from "./atoms";
import NoticeQuillEditor from "../../notice/NoticeQuillEditor";

const UploadSongInfoStep: React.FC = () => {

    const moods = ['신남', '슬픔', '그리움', '사랑', '드라이브', '잠들기전', '잔잔한', '절망', '박진감', '희망찬']
    const [state, setState] = useRecoilState(songUploadFormState)
    const studioState = useRecoilValue(myStudioState)
    const handleChangeGenre = (genre: string) => {
        setState(old => ({...old, genre}))
    }

    const handleChangeMood = (mood: string) => {
        setState(old => ({...old, mood}))
    }

    const handleChangeLyrics = (lyrics: string) => {
        setState(old => ({...old, lyrics}))
    }

    const handleChangeDesc = (desc: string) => {
        setState(old => ({...old, description: desc}))
    }

    const handleChangeAlbumNo = (albumNo: string) => {
        if (albumNo.length > 0)
            setState(old => ({...old, album_no: parseInt(albumNo)}))
    }

    return (
        <div className={'upload_song_info_step_wrapper'}>
            <p className={'step_title'}>* 필수 정보 입니다.</p>
            <Row>
                <Col xs={6}>
                    <Input type={'text'} name={'title'} label={'* 음악 제목'} placeholder={'음악 제목을 입력하여 주세요.'}/>
                </Col>
                <Col xs={6}>
                    <Input type={'text'} name={'lyricist'} label={'* 작사자'} placeholder={'작사자를 입력하여 주세요.'}/>
                </Col>
                <Col xs={6}>
                    <Input type={'text'} name={'songwriter'} label={'* 작곡자'} placeholder={'작곡자를 입력하여 주세요.'}/>
                </Col>
                <Col xs={6}>
                    <Input type={'text'} name={'arranger'} label={'편곡자'} placeholder={'편곡자를 입력하여 주세요.'}/>
                </Col>
                <Col xs={6}>
                    <div className={'label_input_wrapper'}>
                        <label style={{color: 'white'}}>
                            장르
                        </label>
                        <select value={state.genre} onChange={event => handleChangeGenre(event.target.value)}>
                            {genres.map((genre, index) => <option key={index} value={genre.name}>{genre.name}</option>)}
                        </select>
                    </div>
                </Col>
                <Col xs={6}>
                    <div className={'label_input_wrapper'}>
                        <label style={{color: 'white'}}>
                            * 분위기
                        </label>
                        <select value={state.mood} onChange={event => handleChangeMood(event.target.value)}>
                            {moods.map((mood, index) => <option key={index} value={mood}>{mood}</option>)}
                        </select>
                    </div>
                </Col>
                <Col xs={6}>
                    <div className={'label_input_wrapper'}>
                        <label style={{color: 'white'}}>
                            앨범 (수록을 원하는 앨범이 있을경우 선택하여 주세요.)
                        </label>
                        <select onChange={event => handleChangeAlbumNo(event.target.value)}>
                            <option value={''}>
                                없음
                            </option>
                            {studioState.albums.map((album, index) =>
                                <option key={index}
                                        value={album.no}>
                                    {album.album_title}
                                </option>)}
                        </select>
                    </div>
                </Col>
                <Col xs={12}>
                    <Input type={'text'} name={'participation'} label={'함께 참여한 아티스트'}
                           placeholder={'함께 참여한 아티스트를 입력하여 주세요.'}/>
                </Col>
                <Col xs={12}>
                    <div className={'editor_form_wrapper'}>
                        <label>* 가사</label>
                        <NoticeQuillEditor
                            value={state.lyrics}
                            onChange={(value: string) => handleChangeLyrics(value)}
                        />
                    </div>
                </Col>
                <Col xs={12}>
                    <div className={'editor_form_wrapper'}>
                        <label>* 곡 소개</label>
                        <NoticeQuillEditor
                            value={state.description}
                            onChange={(value: string) => handleChangeDesc(value)}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    )
}


export default UploadSongInfoStep