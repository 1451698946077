import React, {useState} from "react"
import coverImage from '../../../assets/images/cover/large/3.jpg'
import {Col, Row} from "react-bootstrap";
import {useRecoilValue} from "recoil";
import {myStudioState} from "../song/atoms";
import {useNavigate} from "react-router-dom";

const AlbumTable: React.FC = () => {
    const navigator = useNavigate()

    const {albums} = useRecoilValue(myStudioState)
    return (
        <div className={'upload_song_table_wrapper'}>
            <Row className={'upload_song_table'}>
                {albums.map((album, index) => {
                    return (
                        <Col
                            onClick={() => navigator(`/album/detail/${album.no}`)}
                            key={index} xs={6} md={4} xl={3}>
                            <div className={'song_item'}>
                                <div>
                                    <img src={coverImage} alt={'cover_img'}/>
                                </div>
                                <div className={'song_info'}>
                                    <h4>{album.album_title}</h4>
                                    <div className={'genre_list'}>
                                        {album.genre.map((gr, index) =>
                                            <p key={index}>
                                                {album.genre.length === (index + 1) ? gr : `${gr},`}
                                            </p>
                                        )}
                                    </div>
                                    <p>곡 설명</p>
                                </div>
                            </div>
                        </Col>
                    )
                })}

            </Row>
        </div>
    )
}

export default AlbumTable;