import {atom} from 'recoil'
import {Album} from "../../model/api/Album";

interface INotice {
    no: number
    title: string
    content: string
    create_time: Date
}

interface IState {
    notices: INotice[]
}


export const noticeState = atom<IState>({
    key: 'noticeState',
    default: {
        notices: []
    }
})


interface IForm {
    title: string
    content: string
}


export const writeNoticeFormState = atom<IForm>({
    key: 'writeNoticeFormState',
    default: {
        title: '',
        content: ''
    }
})