import React, {SyntheticEvent} from "react"
import chartImg from '../../../assets/images/cover/small/1.jpg'
import {Music} from "../../../model/api/Music";
import {IPlayerMusic, musicPlayerListState} from "../../player/atoms";
import {useRecoilState} from "recoil";
import albumImage from "../../../assets/images/cover/small/1.jpg";
import ChartRank from "./ChartRank";

interface IProps {
    rank: number
    music: Music
}

const SmallChartItem: React.FC<IProps> = ({rank, music}) => {

    const [player, setPlayer] = useRecoilState(musicPlayerListState)

    const handleListenMusic = (music: Music) => {
        const newMusic: IPlayerMusic = {
            musicNo: music.no,
            title: music.title,
            artist: music.nickname,
            file: music.file,
            coverImg: music.cover_img
        }
        setPlayer(old => ({...old, players: [newMusic, ...old.players]}))
    }

    const handleImgError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
        e.currentTarget.src = chartImg
    }

    return (
        <div className={'small_chart_item_wrapper'}
             onClick={() => handleListenMusic(music)}>
            <div className={'chart_item_image'}>
                <img src={music.cover_img ? music.cover_img : chartImg}
                     alt={'chart_image'}
                     onError={handleImgError}/>
            </div>
            <div className={'chart_item_rank'}>
                <ChartRank
                    useRankCompare={true}
                    move={music.chart_old - music.chart_new}
                    type={music.chart_new < music.chart_old ? 'up' : 'down'}
                    rank={rank}/>
            </div>
            <div className={'chart_item_info'}>
                <span>{music.title}</span>
                <small>{music.nickname}</small>
            </div>
        </div>
    )
}


export default SmallChartItem