import {atom} from 'recoil'
import {Album} from "../../model/api/Album";


interface IState {
    album: Album
}


export const albumDetailState = atom<IState>({
    key: 'albumDetailState',
    default: {
        album: {
            no: 0,
            artist_no: 0,
            album_title: '',
            genre: [],
            album_img: '',
            create_time: new Date()
        }
    }
})
