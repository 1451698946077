import React from "react"
import Drawer from '@mui/material/Drawer';
import {useRecoilState} from "recoil";
import {IPlayerMusic, musicPlayerListState} from "./atoms";
import CloseIcon from '@mui/icons-material/Close';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import classNames from "classnames";
import {playMusicCount} from "../../helpers/music";

const PlayListDrawer: React.FC = () => {

    const [state, setState] = useRecoilState(musicPlayerListState)

    const handleClose = () => {
        setState(old => ({...old, open: false}))
    }

    const handlePlayMusic = async (music: IPlayerMusic) => {
        setState(old => ({...old, nowPlaying: music}));
        await playMusicCount(music.musicNo);
    }

    return (
        <div className={'play_list_drawer_wrapper'}>
            <Drawer
                anchor={'right'}
                open={state.open}
                onClose={handleClose}
            >
                <div>
                    <div style={{padding: '1rem 0.5rem'}}>
                        <h4>플레이리스트</h4>
                    </div>
                    <div className={'play_list'}>
                        <ul>
                            {state.players.map((playMusic, index) => {
                                return (
                                    <li onClick={() => handlePlayMusic(playMusic)} key={index}>
                                        <div style={{width: '90%', display: 'flex'}}
                                             className={classNames({
                                                 'playing': playMusic.musicNo === state.nowPlaying?.musicNo
                                             })}>
                                            <QueueMusicIcon/>
                                            <dl>
                                                <dt>
                                                    {playMusic.title}
                                                </dt>
                                                <dd>
                                                    {playMusic.artist}
                                                </dd>
                                            </dl>
                                        </div>
                                        <div style={{width: '10%'}}>
                                            <button>
                                                <CloseIcon/>
                                            </button>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </Drawer>
        </div>
    )
}


export default PlayListDrawer