import React, {useEffect} from "react"
import {isLogin} from "../../helpers/auth";
import {Link, useNavigate} from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import baseProfile from '../../assets/images/cover/small/10.jpg'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useRecoilState} from "recoil";
import axios from "axios";
import {BaseResponse} from "../../model/api/BaseResponse";
import {ErrorResponse} from "../../helpers/error";
import {profileState} from "./atoms";

const NavPageTopBar: React.FC = () => {
    const navigator = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const [state, setState] = useRecoilState(profileState)

    useEffect(() => {
        if (isLogin())
            handleGetProfile()
    }, [])

    const handleGetProfile = async () => {
        try {
            const {data} = await axios.get('/user/profile')
            const result = data as BaseResponse

            if (result.success) {
                setState(old => ({...old, ...result.data}))
            }

        } catch (err) {
            ErrorResponse(err);
        }

    }


    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget)
    };
    const handleClose = () => {
        setAnchorEl(null)
    };

    const handleLogout = () => {
        setAnchorEl(null)
        localStorage.removeItem('_uid')
        window.location.replace('/')
    }

    return (
        <div className={'nav_page_top_bar_wrapper'}>
            <div className={'searchbar_section'}>
                {/*<span>*/}
                {/*    Search...*/}
                {/*</span>*/}
            </div>
            <div className={'user_section'}>
                {isLogin() ?
                    <div className={'user_profile'}
                         onClick={handleClick}>
                        {state.profile_img ?
                            <Avatar alt={'profile_image'} src={state.profile_img}/>
                            :
                            <Avatar alt={'profile_image'} src={baseProfile}/>
                        }

                        <p>{state.nickname}</p>
                    </div>
                    :
                    <div className={'auth_btn_wrapper'} style={{padding: '0 1rem'}}>
                        <Link to={'/login'} style={{marginRight: '1rem'}}>
                            로그인
                        </Link>
                        <Link to={'/register'}>
                            회원가입
                        </Link>
                    </div>
                }
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={() => navigator('/myStudio')}>마이 스튜디오</MenuItem>
                    <MenuItem onClick={handleLogout}>로그아웃</MenuItem>
                </Menu>
            </div>
        </div>
    )
}

export default NavPageTopBar