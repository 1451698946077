import {atom} from 'recoil'
import {Album} from "../../model/api/Album";


export interface JudgeMusic {
    comment: string
    create_time: Date
    music_no: number
    no: number
    pass: boolean
    update_time: Date
}

interface IState {
    musics: JudgeMusic[]
}


export const judgeMusicState = atom<IState>({
    key: 'judgeMusicState',
    default: {
        musics: []
    }
})
