import React, {useEffect} from "react"
import NavMain from "../nav/NavMain";
import SmallTopMusicChart from "./musicChart/SmallTopMusicChart";
import SmallNewReleaseChart from "../newRelease/SmallNewReleaseChart";
import {useSearchParams} from "react-router-dom";
import {uuidv4} from "../../helpers/util";

const LandingPage: React.FC = () => {

    const [searchParams] = useSearchParams();
    const accessToken = searchParams.get('access');


    useEffect(() => {
        if (accessToken) {
            localStorage.setItem('_uid', uuidv4());
            localStorage.setItem('_indieTk', accessToken);
            window.location.href = '/';
        }
    }, [accessToken])

    return (
        <NavMain pageTitle={''}>
            <SmallTopMusicChart/>
            <SmallNewReleaseChart/>
        </NavMain>
    )
}


export default LandingPage