import React, {useRef} from 'react'

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    state: any
    setState: any
}

const Input: React.FC<Props> = ({state, setState, ...props}: Props) => {
    const inputRef = useRef<HTMLInputElement>(null)
    return (
            <input
                {...props}
                ref={inputRef}
                data-testid={props.name}
                readOnly
                onFocus={e => {
                    e.target.readOnly = false
                }}
                onChange={e => {
                    setState({...state, [e.target.name]: e.target.value})
                }}
            />
    )
}

export default Input
