import React, {useEffect, useState} from "react"
import NavMain from "../nav/NavMain";
import TopMusicChart from "../landing/musicChart/TopMusicChart";
import {genres} from "./Constant";
import classNames from "classnames";
import {ErrorResponse} from "../../helpers/error";
import axios from "axios";
import {BaseResponse} from "../../model/api/BaseResponse";
import {Music} from "../../model/api/Music";


const GenreChartPage: React.FC = () => {

    const [selGenre, setSelGenre] = useState<string>('Acoustic')
    const [genreMusics, setGenreMusics] = useState<Music[]>([])

    useEffect(() => {
        getGenreChart(selGenre)
    }, [selGenre])

    const getGenreChart = async (genre: string) => {
        try {
            const {data} = await axios.get(`/music/chart?type=genre&text=${genre}`)
            const result = data as BaseResponse

            if (result.success) {
                setGenreMusics(result.data)
            }

        } catch (err: any) {
            ErrorResponse(err)
        }
    }

    return (
        <NavMain pageTitle={'장르 음악'}>
            <div className={'genre_chart_page'}>
                <div className={'genre_chips_wrapper'}>
                    {genres.map((genre, index) => {
                        return (
                            <button key={index} className={classNames({
                                'genre_chip': true,
                                'active': genre.name === selGenre
                            })}
                                    onClick={() => setSelGenre(genre.name)}
                            >
                                {genre.name}
                            </button>
                        )
                    })}
                </div>
                <TopMusicChart musics={genreMusics}/>
            </div>
        </NavMain>
    )
}

export default GenreChartPage