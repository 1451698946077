import React, {useEffect, useState} from "react"
import Input from "./Input";
import {myStudioState} from "../song/atoms";
import {useRecoilState, useRecoilValue} from "recoil";
import {studioProfileFormState} from "./atoms";
import {ErrorResponse} from "../../../helpers/error";
import {notificationAlert} from "../../../helpers/alert";
import axios from "axios";
import {BaseResponse} from "../../../model/api/BaseResponse";
import Swal from "sweetalert2";

interface IProps {
    handleUpdateProfile: (type: string, value: any) => void
}

const ProfileStudioTab: React.FC<IProps> = ({handleUpdateProfile}) => {

    const myStudio = useRecoilValue(myStudioState)
    const [state, setState] = useRecoilState(studioProfileFormState);
    const [authNum, setAuthNum] = useState<string>('')
    const [newPhone, setNewPhone] = useState<string>('')

    useEffect(() => {
        setState(old => ({...old, nickname: myStudio.nickname, phone: myStudio.phone}))
    }, [myStudio])

    const handleUpdateNickname = async () => {
        await handleUpdateProfile('nickname', state.nickname)
    }

    const isEmptyTxt = (txt: string): boolean => {
        return txt.length === 0
    }

    const handleUpdatePassword = async () => {
        try {
            const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,16}/

            if (state.password !== state.passwordConfirm) {
                notificationAlert('알림', '비밀번호가 일치하지 않습니다.')
                return
            }

            if (!passwordRegex.test(state.password)) {
                notificationAlert('알림', '8~16자 영문 대 소문자, 숫자, 특수문자를 사용하세요.')
                return
            }

            const {data} = await axios.patch('/user/password', {
                oldPassword: state.oldPassword,
                newPassword: state.password
            })
            const result = data as BaseResponse

            if (result.success) {
                notificationAlert('알림', '비밀번호가 변경되었습니다.')
                setState(old => ({...old, oldPassword: '', password: '', passwordConfirm: ''}))
            }

        } catch (err: any) {
            ErrorResponse(err)
        }
    }


    const handleUpdatePhone = async () => {
        Swal.fire({
            title: '변경하실 휴대폰 번호를 입력하여 주세요.',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: false,
            confirmButtonText: '인증번호 전송',
            showLoaderOnConfirm: true,
            preConfirm: async (phoneNum) => {
                try {
                    setAuthNum(String(Math.floor(Math.random() * 90000) + 10000))
                    setNewPhone(phoneNum)
                    return await axios.post('/auth/confirm/phone', {phone: phoneNum, code: authNum})
                } catch (err: any) {
                    ErrorResponse(err)
                }

            },
            allowOutsideClick: true
        }).then((result) => {

            if (result.isConfirmed && result.value?.status === 200) {
                Swal.fire({
                    title: '인증 번호를 입력하여 주세요.',
                    input: 'text',
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    showCancelButton: true,
                    confirmButtonText: '인증하기',
                    showLoaderOnConfirm: true,
                    preConfirm: (userCodeNum) => {
                        if (authNum === userCodeNum) {
                            //성공
                            handleUpdateProfile('phone', newPhone)
                            setState(old => ({...old, phone: newPhone}))
                            setNewPhone('')
                            setAuthNum('')
                        } else {
                            notificationAlert('오류', '잘못된 인증번호 입니다.');
                        }
                    },
                    allowOutsideClick: false
                })
            }
        })
    }

    return (
        <div className={'profile_studio_tab'}>
            <div>
                <Input type={'text'} name={'nickname'} label={'닉네임'} placeholder={'닉네임을 입력하여 주세요.'}
                       value={state.nickname}/>
                <button onClick={handleUpdateNickname}>닉네임 변경</button>
            </div>
            <div>
                <Input disabled={true}
                       type={'text'}
                       name={'phone'}
                       label={'휴대전화 번호'}
                       placeholder={'휴대전화 번호를 입력하여 주세요.'}
                       value={state.phone}/>
                <button onClick={handleUpdatePhone}>휴대전화 변경</button>
            </div>
            <div>
                <Input type={'password'} name={'oldPassword'} label={'이전 비밀번호'} placeholder={'이전 비밀번호를 입력하여 주세요.'}/>
                <Input type={'password'} name={'password'} label={'새로운 비밀번호'} placeholder={'변경하실 비밀번호를 입력하여 주세요.'}/>
                <Input type={'password'} name={'passwordConfirm'} label={'비밀번호 확인'}
                       placeholder={'변경하실 비밀번호를 입력하여 주세요.'}/>
                <button onClick={handleUpdatePassword}
                        disabled={isEmptyTxt(state.oldPassword) || isEmptyTxt(state.password) || isEmptyTxt(state.passwordConfirm)}>
                    비밀번호 변경
                </button>
            </div>
        </div>

    )
}

export default ProfileStudioTab