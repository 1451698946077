import {atom, selector} from 'recoil'
import {myStudioState} from "../song/atoms";


interface Form {
    nickname: string
    phone: string
    profile_img: File[]
    oldPassword: string
    password: string
    passwordConfirm: string
}


export const studioProfileFormState = atom<Form>({
    key: 'studioProfileFormState',
    default: {
        nickname: '',
        phone: '',
        profile_img: [],
        oldPassword: '',
        password: '',
        passwordConfirm: ''
    }
})

