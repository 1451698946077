import React, {useEffect} from "react"
import NavMain from "../nav/NavMain";
import {ErrorResponse} from "../../helpers/error";
import axios from "axios";
import {BaseResponse} from "../../model/api/BaseResponse";
import {useRecoilState} from "recoil";
import {judgeMusicState} from "./atoms";
import {Col, Row} from "react-bootstrap";
import classNames from "classnames";
import {notificationAlert} from "../../helpers/alert";
import Swal from "sweetalert2";


const JudgeMusicPage: React.FC = () => {

    const [state, setState] = useRecoilState(judgeMusicState)

    useEffect(() => {
        getJudgeMusics()
    }, [])

    const getJudgeMusics = async () => {
        try {
            const {data} = await axios.get('/admin/judge')
            const result = data as BaseResponse

            if (result.success) {
                setState(old => ({...old, musics: result.data}))
            }

        } catch (err: any) {
            ErrorResponse(err)
            if (err.response) {
                if (err.response.status === 403) {
                    window.location.href = '/'
                }
            }
        }
    }

    const handleJudgeMusic = async (judgeNo: number, musicNo: number, pass: boolean, comment?: string) => {
        try {

            const {data} = await axios.post('/admin/judge', {
                no: judgeNo,
                pass,
                comment
            })
            const result = data as BaseResponse

            if (result.success) {
                notificationAlert('알림', '심사가 완료되었습니다.')
                const findIndex = state.musics.findIndex(music => music.no === judgeNo);
                let newJudgeMusics = state.musics
                if (findIndex > 0) {
                    newJudgeMusics[findIndex].pass = pass
                    if (comment != null) {
                        newJudgeMusics[findIndex].comment = comment
                    }
                }
                window.location.reload();
            }
        } catch (err) {
            ErrorResponse(err)
        }
    }

    const handleDenyJudgeMusic = async (judgeNo: number, musicNo: number, pass: boolean) => {
        try {

            Swal.fire({
                title: '심사 거부 사유를 입력하여 주세요.',
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: false,
                confirmButtonText: '거부',
                showLoaderOnConfirm: true,
                preConfirm: async (comment) => {
                    try {

                        return await axios.post('/admin/judge', {
                            no: judgeNo,
                            pass,
                            comment
                        })
                    } catch (err: any) {
                        ErrorResponse(err)
                    }

                },
                allowOutsideClick: true
            }).then((result) => {

                if (result.isConfirmed && result.value?.status === 200) {
                    notificationAlert('알림', '심사가 완료되었습니다.');
                    window.location.reload();
                }
            })

        } catch (err) {
            ErrorResponse(err)
        }
    }


    return (
        <NavMain pageTitle={'음악 업로드 심사'}>
            <div className={'judge_music_page_wrapper'}>
                {state.musics.map((music, index) => {
                    return (
                        <div key={index} className={'judge_music'}>
                            <Row>
                                <Col xs={4}>
                                    <p>심사 No.</p>
                                    <p>{music.no}</p>
                                </Col>
                                <Col xs={4}>
                                    <p>곡 No.</p>
                                    <p>{music.music_no}</p>
                                </Col>
                                <Col xs={4}>
                                    <p>심사 상태</p>
                                    <p className={classNames({
                                        'pass': music.pass,
                                        'wait': !music.pass,
                                        'deny': (music.comment.length > 0 && !music.pass)
                                    })}>{music.pass ? '심사통과' : music.comment.length === 0 ? '심사대기중' : '심사거부'}</p>
                                </Col>
                                <Col xs={4}>
                                    <button
                                        id={'song_info_btn'}
                                        onClick={() => window.open(`/song/detail/${music.music_no}`)}
                                    >곡 정보
                                    </button>
                                </Col>
                                <Col xs={4}>
                                    <button id={'song_pass_btn'}
                                            onClick={() => handleJudgeMusic(music.no, music.music_no, true, "")}>
                                        통과
                                    </button>
                                </Col>
                                <Col xs={4}>
                                    <button
                                        onClick={() => handleDenyJudgeMusic(music.no, music.music_no, false)}
                                        id={'song_deny_btn'}>거부
                                    </button>
                                </Col>
                            </Row>
                        </div>
                    )
                })}
            </div>
        </NavMain>
    )
}

export default JudgeMusicPage