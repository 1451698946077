import React from "react"
import {INavBarItems} from "./Constant";
import {Link} from "react-router-dom";


interface IProps {
    nav: INavBarItems
}

const NavItemBox: React.FC<IProps> = ({nav}) => {

    return (
        <div className={'nav_item_box'}>
            <div className={'box_header'}>
                <p>{nav.title}</p>
            </div>
            <ul>
                {nav.items.map((item, index) => {
                    return (
                        <li key={index}>
                            <Link className={'nav_link'} to={item.link}>
                                {item.icon}
                                <span>
                                {item.title}
                                    </span>
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}


export default NavItemBox