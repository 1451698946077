import {atom} from "recoil";

interface IProfile {
    no: number
    nickname: string
    profile_img?: string
}

export const profileState = atom<IProfile>({
    key: 'profileState',
    default: {
        no: 0,
        nickname: ''
    }
})
