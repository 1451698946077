import React, {useEffect, useState} from "react"
import Modal from '@mui/material/Modal';
import Input from "./Input";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import UploadFileStep from "./UploadFileStep";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {songUploadFormState, songUploadPolicyState} from "./atoms";
import UploadSongInfoStep from "./UploadSongInfoStep";
import {ErrorResponse} from "../../../helpers/error";
import axios from "axios";
import {BaseResponse} from "../../../model/api/BaseResponse";
import {notificationAlert} from "../../../helpers/alert";
import UploadSongPolicyStep from "./UploadSongPolicyStep";

const steps = ['파일 등록', '곡 정보 입력', '약관 동의'];


interface IProps {
    handleClose: () => void
    open: boolean
}

const UploadSongDialog: React.FC<IProps> = ({handleClose, open}) => {
    const [state, setState] = useRecoilState(songUploadFormState)
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set<number>());
    const resetForm = useResetRecoilState(songUploadFormState);
    const policyState = useRecoilValue(songUploadPolicyState);
    useEffect(() => {
        resetForm()
    }, [])

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = async () => {
        try {
            if (activeStep === steps.length - 1) {
                const formData = new FormData();
                const formKeys = Object.keys(state)
                for (let key of formKeys) {
                    if (key === 'coverImage' || key === 'song') {
                        formData.append('file', state[key]);
                    } else {
                        formData.append(key, state[key].toString());
                    }
                }

                const {data} = await axios.post('/music', formData)
                const result = data as BaseResponse
                if (result.success) {
                    notificationAlert('알림', '곡 심사 신청이 완료되었습니다.')
                    resetForm()
                    handleClose()
                }
            }

            let newSkipped = skipped;
            if (isStepSkipped(activeStep)) {
                newSkipped = new Set(newSkipped.values());
                newSkipped.delete(activeStep);
            }

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);
        } catch (err: any) {
            ErrorResponse(err)
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    return (
        <Modal
            className={'dialog'}
            open={open}
            onClose={handleClose}
        >
            <div className={'upload_song_dialog_wrapper'}>
                <div className={'dialog_wrapper'}>
                    <div className={'dialog_xl dialog_background'}>
                        <div className={'dialog_title'}>
                            <h3>음악 업로드</h3>
                        </div>
                        <div className={'dialog_content'}>
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => {
                                    const stepProps: { completed?: boolean } = {};
                                    const labelProps: {
                                        optional?: React.ReactNode;
                                    } = {};

                                    if (isStepSkipped(index)) {
                                        stepProps.completed = false;
                                    }
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                            {activeStep === 0 && <UploadFileStep/>}
                            {activeStep === 1 && <UploadSongInfoStep/>}
                            {activeStep === 2 && <UploadSongPolicyStep/>}

                            <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                                <Button
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{mr: 1, color: 'white'}}
                                >
                                    이전
                                </Button>
                                <Box sx={{flex: '1 1 auto'}}/>
                                <Button disabled={(activeStep === steps.length - 1) && !policyState.policy} onClick={handleNext}>
                                    {activeStep === steps.length - 1 ? '업로드' : '다음'}
                                </Button>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>

    )
}


export default UploadSongDialog