import React, {useState} from "react"
import coverImage from '../../../assets/images/cover/large/3.jpg'
import {Col, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {judgeMusicState, myStudioState} from "./atoms";

interface IProps {
    status: string
}

const UploadSongTable: React.FC<IProps> = ({status}) => {

    const navigator = useNavigate()
    const studioState = useRecoilValue(myStudioState)
    const musics = useRecoilValue(judgeMusicState)

    return (
        <div className={'upload_song_table_wrapper'}>
            <Row className={'upload_song_table'}>
                {musics[status] && musics[status].map((music, index) => {
                    return (
                        <Col
                            onClick={() => navigator(`/song/detail/${music.no}`)}
                            key={index} xs={6} md={4} xl={3}>
                            <div className={'song_item'}>
                                <div>
                                    <img src={coverImage} alt={'cover_img'}/>
                                </div>
                                <div className={'song_info'}>
                                    <h4>{music.title}</h4>
                                    <p>{music.nickname}</p>
                                    {/*{studioState.isMine &&*/}
                                    {/*    <>*/}
                                    {/*        {music.pass ?*/}
                                    {/*            <p style={{color: 'purple'}}>*/}
                                    {/*                심사완료*/}
                                    {/*            </p>*/}
                                    {/*            :*/}
                                    {/*            <p style={{color: 'yellowgreen'}}>*/}
                                    {/*                심사 대기중*/}
                                    {/*            </p>*/}
                                    {/*        }*/}
                                    {/*    </>}*/}
                                </div>
                            </div>
                        </Col>
                    )
                })}
            </Row>
        </div>
    )
}

export default UploadSongTable;