import React, {useState} from "react"
import AlbumTable from "./AlbumTable";
import CreateAlbumDialog from "./CreateAlbumDialog";
import {useRecoilValue} from "recoil";
import {myStudioState} from "../song/atoms";

const AlbumStudioTab: React.FC = () => {

    const [showDialog, setShowDialog] = useState<boolean>(false)
    const studioInfo = useRecoilValue(myStudioState)
    const handleClose = () => {
        setShowDialog(false)
    }

    return (
        <div className={'song_upload_wrapper'}>
            {studioInfo.isMine &&
                <div className={'upload_btn_wrapper'}>
                    <div/>
                    <button onClick={() => setShowDialog(old => !old)}>앨범 생성</button>
                </div>
            }

            <AlbumTable/>
            <CreateAlbumDialog handleClose={handleClose} open={showDialog}/>

        </div>
    )
}


export default AlbumStudioTab