import React from "react"
import {AuthPhoneBase} from "../../common";
import {useRecoilState} from "recoil";
import {registerFormState} from "./atoms";


const AuthPhone: React.FC = () => {

    const [state, setState] = useRecoilState(registerFormState)

    return (
        <AuthPhoneBase state={state} setState={setState}/>
    )
}

export default AuthPhone