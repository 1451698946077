import React from "react"
import mainLogo from '../../assets/images/logos/logo.svg'
import {navbarItems} from "./Constant";
import NavItemBox from "./NavItemBox";

const LeftSideNavbar: React.FC = () => {


    return (
        <div className={'left_side_navbar_wrapper'}>
            <div className={'side_navbar_header'}>
                <img
                    style={{cursor: 'pointer'}}
                    onClick={() => window.location.href = '/'}
                    src={mainLogo}
                    alt={'main_logo'}/>
            </div>
            <div className={'side_navbar_links'}>
                <ul>
                    {navbarItems.map((item, index) => <li key={index}>
                        <NavItemBox nav={item}/>
                    </li>)}
                </ul>
            </div>
        </div>
    )
}


export default LeftSideNavbar