import {notificationAlert} from "./alert";


export const ErrorResponse = (err: any) => {
    console.log(err)
    if (err.response) {
        notificationAlert('오류', err.response.data.message)
    } else {
        notificationAlert('오류', '문제가 발생하였습니다.')
    }

}