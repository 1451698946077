import React from "react";
import NavMain from "../nav/NavMain";


const termsTxt = '\n' +
    '제1장 총칙\n' +
    '제1조 목적\n' +
    '이 약관은 인디스트림(이하 “회사”라고 합니다)이 제공하는 인디스트림 플랫폼(이하 “플랫폼”이라고 합니다) 상의 서비스 이용과 관련하여 회사와 회원의 권리, 의무 및 책임 사항, 이용 절차와 기타 필요 사항의 규정을 목적으로 합니다.\n' +
    '제2조 정의\n' +
    '① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.\n' +
    '1. “음원”은 “다운로드 받거나 실시간 스트리밍하여 재생할 수 있는 디지털 음악 데이터”를 말합니다.\n' +
    '2. “전송”은 공중송신 중 공중의 구성원이 개별적으로 선택한 시간과 장소에서 접근할 수 있도록 저작물 등을 이용에 제공하는 것을 말하며, 그에 따라 이루어지는 송신을 포함합니다.\n' +
    '3. “디지털음성송신”은 공중송신 중 공중으로 하여금 동시에 수신하게 할 목적으로 공중의 구성원의 요청에 의하여 개시되는 디지털 방식의 음의 송신을 말하며, 전송을 제외합니다.\n' +
    '4. “실연”은 저작물을 연기ㆍ무용ㆍ연주ㆍ가창ㆍ구연ㆍ낭독 그 밖의 예능적 방법으로 표현하거나 저작물이 아닌 것을 이와 유사한 방법으로 표현하는 것을 말합니다.\n' +
    '5. “공연”은 저작물 또는 실연ㆍ음반ㆍ방송을 상연ㆍ연주ㆍ가창ㆍ구연ㆍ낭독ㆍ상영ㆍ재생 그 밖의 방법으로 공중에게 공개하는 것을 말하며, 동일인의 점유에 속하는 연결된 장소 안에서 이루어지는 송신을 포함합니다.\n' +
    '② 나머지 용어에 대해서는 저작권법 등 관련 법령에서 사용하는 정의를 따릅니다.\n' +
    '제3조 약관의 게시와 개정\n' +
    '① 회사는 이 약관의 내용을 회원이 쉽게 알 수 있도록 서비스 내 또는 연결화면을 통하여 게시합니다.\n' +
    '② 회사는 필요한 경우 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.\n' +
    '③ 회사가 이 약관을 개정할 경우에는 개정 내용과 적용 일자를 명시하여 서비스에서 적용일의 7일 전부터 적용일의 전날까지 공지합니다.\n' +
    '④ 회원은 개정약관에 동의하지 않는 경우 적용일의 전날까지 회사에 거부 의사를 표시하고 서비스 이용계약을 해지할 수 있습니다.\n' +
    '⑤ 회사가 회원에게 ‘적용일의 전날까지 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다’는 뜻을 명확하게 공지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.\n' +
    '제4조 약관의 해석\n' +
    '① 회사는 이 약관 외에 별도의 운영정책을 둘 수 있습니다.\n' +
    '② 이 약관에서 정하지 아니한 사항이나 해석에 대해서는 운영정책, 이용안내, 관련 법령에 따릅니다.\n' +
    '제2장 이용계약 및 정보보호에 관한 사항\n' +
    '제5조 가입요건\n' +
    '① 회원은 본 약관 및 만 14세 이상임에 동의하고 서비스를 이용함으로써 성립됩니다.\n' +
    '② 법정 대리인의 동의가 필요한 만 14세 미만 청소년의 회원가입 및 서비스 이용은 불가합니다.\n' +
    '제6조 이용계약의 체결\n' +
    '① 이용계약은 회원이 되고자 하는 사람(이하 “가입 신청자”라고 합니다)이 회사가 제공하는 약관의 내용에 대하여 동의한 다음 회사가 정한 절차에 따라 가입신청을 완료하고, 회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.\n' +
    '② 회사는 가입 신청자가 제5조의 가입요건을 갖추어 가입신청을 한 경우에도 아래 각호의 사유가 있는 경우에는 이를 승낙하지 않을 수 있습니다.\n' +
    '1. 가입 신청자가 이 약관에 의하여 이전에 회원자격을 상실한 전력이 있는 경우\n' +
    '2. 실명이 아닌 명의 또는 타인 명의를 이용한 경우\n' +
    '3. 허위 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 아니한 경우\n' +
    '4. 가입 신청자의 귀책 사유로 인하여 승인할 수 없거나 기타 이 약관에서 규정한 제반 사항을 위반하며 신청하는 경우\n' +
    '5. 부정한 용도 또는 별개의 영업 목적으로 서비스를 이용하고자 하는 경우\n' +
    '6. 관련 법령에 어긋나거나 사회상규를 저해할 수 있는 목적으로 신청한 경우\n' +
    '7. 서비스 이용을 제한하는 특정 국적의 외국인인 경우\n' +
    '8. 기타 이에 준하는 경우\n' +
    '③ 이용계약의 성립 시기는 회사가 계정 생성 완료를 신청 절차상에서 표시하거나 제26조의 방식에 따른 통지가 가입 신청자에게 도달한 시점으로 합니다.\n' +
    '④ 회사는 회원이 제공한 정보가 사실과 일치하는지 확인하기 위하여 법령에 의하여 허용된 범위에서 전문기관을 통한 실명확인 또는 본인인증을 요청할 수 있습니다.\n' +
    '제7조 서비스 이용의 제한\n' +
    '① 회사는 다음 각호에 해당하는 경우 회원의 서비스 로그인을 제한할 수 있습니다.\n' +
    '1. 비밀번호 연속 오류의 경우\n' +
    '2. 해킹 및 사기 사고가 발생한 경우\n' +
    '3. 국가기관이 관련 법령에 따라 서비스 제한을 요청하는 경우\n' +
    '4. 서비스가 범죄행위에 이용되고 있다고 합리적으로 의심되는 경우\n' +
    '5. 회원으로 가입된 이용자가 가입요건을 갖추지 못한 것으로 확인된 경우\n' +
    '6. 제13조를 위반한 것으로 의심되거나, 그러한 사유 발생의 방지를 위해 필요한 경우\n' +
    '② 이용 제한의 세부 내용은 운영정책, 이용안내 등에서 회사가 정하는 바에 의합니다.\n' +
    '③ 본 조에 따라 서비스 이용을 제한하는 경우 회사는 제26조에 따른 방법으로 회원에게 통지합니다.\n' +
    '④ 회원은 본 조에 따른 이용제한에 대해 고객센터를 통하여 이의신청을 할 수 있고, 이때 이의가 정당하다고 회사가 인정하는 경우 회사는 즉시 서비스 제공을 재개합니다.\n' +
    '제8조 이용계약의 해지\n' +
    '① 회원은 언제든지 플랫폼 내 정보 관리 또는 설정 메뉴 또는 고객센터 등을 통하여 이용계약의 해지를 신청할 수 있으며, 회사는 관련 법령이 정하는 바에 따라 이를 즉시 처리하여야 합니다.\n' +
    '② 회원이 본 약관에 규정된 회원의 의무를 위반한 경우 회사는 기간을 정하여 서비스 이용을 제한함과 동시에 시정을 요구할 수 있으며, 상당 기간 내에 시정되지 않거나 반복적으로 같은 위반을 하는 경우에는 이용계약을 해지할 수 있습니다.\n' +
    '③ 전항에도 불구하고, 회사는 회원의 의무 위반이 중한 경우 즉시 이용계약을 해지할 수 있습니다.\n' +
    '④ 전항에 따른 이용계약 해지 시 서비스 이용을 통해 획득한 모든 혜택이 소멸하며, 회사는 이에 대해 별도로 보상하지 않습니다.\n' +
    '⑤ 본 조에 따라 서비스 이용계약을 해지하는 경우에는 회사는 제26조에 따른 방법으로 회원에게 통지합니다.\n' +
    '⑥ 이용계약 해지가 완료되는 경우 관련 법령 및 개인정보 처리방침에 따라 회사가 보유하여야 하는 정보를 제외한 회원의 모든 정보가 삭제됩니다.\n' +
    '⑦ 전항에도 불구하고, 제2항에 따라 회사가 이용계약을 해지하는 경우 회사는 회원의 이의신청 접수 및 처리 등을 위하여 일정 기간 회원의 정보를 보관할 수 있습니다.\n' +
    '제9조 회원 정보의 변경\n' +
    '① 회원은 개인정보 관리화면을 통하여 언제든지 본인의 개인정보를 열람하거나 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 실명, 아이디 등은 수정이 불가합니다.\n' +
    '② 회원은 회원가입 신청 시 기재한 사항이 변경되었을 경우 플랫폼에서 직접 수정하거나 고객센터를 통하여 회사에 그 변경 사항을 알려야 합니다.\n' +
    '③ 회원이 전항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임을 부담하지 않습니다.\n' +
    '제10조 회원 정보의 관리\n' +
    '① 회사는 회원 닉네임의 내용 또는 표현이 다음 각호에 해당하는 경우 해당 닉네임의 사용을 제한할 수 있습니다.\n' +
    '1. 회원 본인이 아닌 타인으로 오해할 소지가 있는 경우\n' +
    '2. 타인의 권리를 침해할 우려가 있는 경우\n' +
    '3. 회사 및 회사의 운영자로 오인할 우려가 있는 경우\n' +
    '4. 사회상규에 반하는 경우\n' +
    '5. 기타 이에 준하는 경우\n' +
    '② 회사는 회원이 입력한 계정과 비밀번호 등이 회사에 등록된 것과 일치할 경우 별도의 확인절차 없이 이용자를 회원으로 간주합니다.\n' +
    '③ 회원은 본인의 허가를 받지 않은 타인이 회원의 계정에 무단으로 접근하지 않도록 비밀번호를 포함하여 계정 접근에 필요한 일체의 정보를 안전하게 보관할 책임이 있습니다.\n' +
    '④ 회원은 계정 접근정보를 분실했거나, 도용당했거나, 타인에게 공개되었음을 인지한 경우 이를 즉시 회사에 통지해야 하고, 이에 대해 회사는 계정 이용중단 등의 조처를 할 수 있습니다.\n' +
    '⑤ 회원의 계정 비밀번호 등 접근정보가 분실, 도용 혹은 공개되어 회원에게 발생한 손해에 대하여 회사는 고의 또는 과실이 없는 한 책임을 부담하지 아니합니다.\n' +
    '제11조 개인정보의 보호\n' +
    '① 회사는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보 보호법 등 관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다.\n' +
    '② 회원 개인정보의 보호 및 이용에 대해서는 관련 법령 및 회사의 개인정보 처리방침이 적용됩니다. 다만, 회사가 제작하여 제공한 화면 이외의 외부로 링크된 화면 등에서는 회사의 개인정보처리방침이 적용되지않습니다.\n' +
    '제3장 당사자의 권리와 의무에 관한 사항\n' +
    '제12조 회사의 기본적인 의무\n' +
    '① 회사는 관련 법령과 이 약관을 준수하며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다합니다.\n' +
    '② 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함) 보호를 위해 보안시스템을 갖출 수 있으며, 개인정보 처리방침을 공시하고 준수합니다.\n' +
    '③ 회사는 서비스 이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우 이를 처리하여야 하며, 플랫폼 내 게시판, 전자우편 등을 통하여 회원에게 처리 과정 및 결과를 전달할 수 있습니다.\n' +
    '제13조 회원의 기본적인 의무\n' +
    '① 회원은 다음 각호의 행위를 하여서는 아니 됩니다.\n' +
    '1. 회원가입 신청 또는 회원정보 변경 시 허위내용의 등록\n' +
    '2. 타인의 정보도용\n' +
    '3. 회사가 게시한 정보의 무단 변경\n' +
    '4. 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시\n' +
    '5. 회사 또는 타인의 지식재산권에 대한 침해\n' +
    '6. 회사 또는 타인에 대한 명예훼손 또는 업무방해\n' +
    '7. 외설적이거나 폭력적인 메시지, 화상, 음성, 기타 사회상규에 반하는 정보의 게시\n' +
    '8. 회사의 사전 동의 없이 영리 목적으로 서비스 이용\n' +
    '9. 회사의 사전 동의 없이 에이전트(Agent), 스크립트(Script), 스파이더(Spider), 스파이웨어(Spyware), 툴바(Toolbar) 등의 자동화된 수단, 기타 부정한방법을 통하여,\n' +
    '서비스에 접속하거나, 노출 횟수 또는 클릭 횟수를 부정하게 생성 또는 증가시키거나, 서비스 이용 신청을 하거나, 서버에 부하를 일으키는 행위\n' +
    '10. 다른 회원의 개인정보에 대한 무단 수집\n' +
    '11. 기타 이에 준하는 부정행위\n' +
    '② 회원은 관계 법령, 이 약관, 이용안내, 회사가 공지하거나 통지한 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 아니 됩니다.\n' +
    '제14조 게시물의 저작권 귀속\n' +
    '① 회원이 플랫폼 내에 게시한 음원 등의 저작권(저작인접권을 포함하며, 이하 같습니다)은 저작권법에 의하여 보호되며, 저작권자인 회원은 이를 다른 매체에서 상업적으로 이용할 수 있습니다.\n' +
    '② 회원의 저작권 보유에 병행하여, 회사는 아래와 같은 범위 내에서 기간의 제한 없이 음원 등의 게시물을 무료로 사용할 수 있는 권리를 보유합니다.\n' +
    '1. 플랫폼의 운영, 향상, 개선, 신규 개발, 홍보 등을 위하여 게시물을 사용, 편집, 저장, 복제, 수정, 공개, 전송, 디지털음성송신, 실연, 공연, 게시, 배포하는 행위\n' +
    '2. 홍보 목적으로 다른 매체로 하여금 게시물의 내용을 보도하거나 방영하게 하는 행위\n' +
    '③ 회사가 전항 이외의 방법으로 게시물을 상업적으로 이용하고자 하는 경우 사전에 저작권자인 회원의 동의를 얻어야 합니다.\n' +
    '④ 이용계약이 해지되는 경우에도 제2항에 따른 회사의 무료 사용권은 존속합니다.\n' +
    '제15조 게시물 이용권의 제한\n' +
    '① 회원은 타인의 지식재산권 및 기타 권리를 침해하는 목적으로 게시물을 사용할 수 없으며, 타인의 권리를 침해하는 행위로 인하여 발생하는 결과에 대한 모든 책임은 회원 본인에게 있습니다.\n' +
    '② 회원은 타인의 게시물을 무단으로 상업적이거나 기타 개인적인 이익을 위한 용도로 사용할 수 없습니다.\n' +
    '③ 회원이 타인의 게시물을 임의로 사용하여 회사에 피해를 줄 경우 회원은 회사에 법적인 절차에 따른 피해보상 의무가 있습니다.\n' +
    '제16조 게시물에 대한 강제조치\n' +
    '① 게시물이 다음 각호에 해당하는 경우 회사는 게시자의 동의 없이 게시 중단 또는 삭제 등의 조처를 할 수 있습니다.\n' +
    '1. 허위사실을 포함하는 경우\n' +
    '2. 타인의 명예를 훼손하는 경우\n' +
    '3. 표절 등 타인의 저작권을 침해하는 경우\n' +
    '4. 관련 법령 또는 이용약관 등에 위반되는 경우\n' +
    '5. 기타 이에 준하는 경우\n' +
    '② 회사는 전항에 해당하는 게시물을 게시하는 회원에 대해 이용계약을 해지할 수 있습니다.\n' +
    '제17조 서비스에 대한 권리의 귀속\n' +
    '① 서비스 자체에 대한 지식재산권은 회사에 귀속됩니다.\n' +
    '② 회사는 회원에게 회사가 정한 이용조건에 따라 서비스 등을 이용할 수 있는 서비스 이용권을 부여하며, 회원은 이에 대한 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다.\n' +
    '제4장 서비스에 관한 사항\n' +
    '제18조 서비스의 연속적인 제공\n' +
    '① 회사는 연중무휴, 1일 24시간 끊임없이 서비스를 제공합니다. 다만 회사는 서비스를 일정 범위로 나누어 범위별로 이용 가능 시간을 별도로 지정할 수 있으며, 그 내용을 사전에 공지합니다.\n' +
    '② 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체, 고장, 기타 운영상의 합리적인 사정이 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 회사는 사전에 서비스 초기 화면이나 공지사항 게시판을 통하여 공지합니다. 다만, 부득이한 사유가 있는 경우에는 사후에 공지할 수 있습니다.\n' +
    '③ 회사는 서비스 제공에 필요한 점검을 할 수 있으며, 점검 시간은 플랫폼에 공지합니다.\n' +
    '제19조 서비스의 변경\n' +
    '① 회사는 안정적인 서비스 제공을 위하여 서비스의 내용 또는 운영 등에 관한 사항을 변경할 수 있습니다.\n' +
    '② 회사는 서비스를 변경할 경우 변경내용과 적용 일자를 명시하여 사전에 공지합니다. 다만, 부득이한 사유가 있는 경우 사후에 공지할 수 있습니다.\n' +
    '③ 회원은 서비스 변경에 동의하지 않을 경우 회사에 거부 의사를 표시하고 이용계약을 해지할 수 있습니다.\n' +
    '제20조 음원 스트리밍\n' +
    '① 회사는 게시된 음원에 대한 스트리밍 서비스를 제공합니다.\n' +
    '② 부수적으로 회사는 콘서트 또는 팬미팅 등의 오프라인 행사에 대한 라이브 스트리밍 서비스를 제공할 수 있습니다.\n' +
    '제21조 음원 소개 및 일정 게시\n' +
    '① 회사는 회원의 인지도 제고 등을 위해 다음과 같은 서비스를 제공합니다.\n' +
    '1. 플랫폼에 게시된 음원 중에서 순차적으로 일부를 선정하여 플랫폼 상에서 일정 기간 자세히 소개하는 서비스\n' +
    '2. 아티스트 회원이 공연 또는 앨범 출시 등의 개인적인 음악활동 관련 일정을 플랫폼 상에서 직접 소개하는 서비스\n' +
    '② 전항의 서비스와 관련하여 회원이 직접 작성하여 게시한 내용에 관하여는 회사가 책임을 부담하지 않습니다.\n' +
    '제22조 온라인/오프라인 행사 개최\n' +
    '① 회사는 아티스트 회원이 출연하는 공연 또는 팬미팅 등의 온라인/오프라인 행사를 개최할 수 있습니다.\n' +
    '② 전항의 행사에 출연할 아티스트 회원은 당사자의 동의 아래 회사가 인기도 등을 고려하여 선정할 수 있습니다.\n' +
    '③ 회사는 제1항의 행사에 대한 방청권을 커뮤니티 활동이 우수한 회원에게 무료로 교부할 수 있습니다.\n' +
    '제23조 회원에 대한 통지\n' +
    '1. 회사가 회원에 대해 통지를 하는 경우 이 약관에 별도 규정이 없는 한 회원 가입 시 등록된 전자우편 또는 문자메시지 등으로 할 수 있습니다.\n' +
    '2. 회사는 불특정 다수 회원에 대한 통지의 경우 7일 이상 서비스 초기 화면에 게시함으로써 전항의 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별적으로 통지합니다.\n' +
    '제24조 책임의 제한\n' +
    '① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우 회사의 고의 또는 중과실이 없는 한 서비스 제공에 관한 책임이 면제됩니다.\n' +
    '② 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 회사의 고의 또는 중과실이 없는 한 책임을 부담하지 않습니다.\n' +
    '③ 회사는 회원이 게시한 정보, 자료, 사실의 신뢰도 내지 정확성 등에 관하여 회사의 고의 또는 중과실이 없는 한 책임을 부담하지 않습니다.\n' +
    '④ 회사는 회원이 플랫폼에 게시된 제휴업체 등과 거래를 하여 발생하는 손해에 대해 회사의 고의 또는 중과실이 없는 한 책임을 부담하지 않습니다.\n' +
    '⑤ 회원이 서비스 내에 포함된 링크를 통하여 다른 웹사이트로 옮겨갈 경우 회사는 해당 사이트에서 제공하는 정보 내용 및 이로 인한 손해 등에 대하여 회사의 고의 또는 중과실이 없는 한 책임을 부담하지 않습니다.\n' +
    '제25조 유료 회원제\n' +
    '① 회사는 플랫폼 운영에 필요한 재원 마련을 위해 유료 회원제를 시행할 수 있습니다.\n' +
    '② 회사는 유료 회원제를 통해 조성되는 자금을 회원의 창작활동 지원 및 팬덤 형성 등에 활용합니다.\n' +
    '③ 회원은 유료 회원 가입일로부터 7일 이내에 가입철회를 신청할 경우 가입비를 전액 돌려받을 수 있으며, 그 이후에는 소정의 수수료가 차감될 수 있습니다.\n' +
    '제5장 보칙\n' +
    '제26조 준거법 및 재판관할\n' +
    '1. 회사와 회원간 제기된 소송은 대한민국 법을 준거법으로 합니다.\n' +
    '2. 만일 법적인 분쟁이 발생할 경우 당사의 본사 소재지를 관할하는 법원에 제기합니다.\n' +
    '제27조 적용일자\n' +
    '이 약관은 2022년 12월 1일부터 적용됩니다.';

const TermsPage: React.FC = () => {

    return (
        <NavMain pageTitle={'인디스트림 이용약관'}>
            <div className={'terms_wrapper'}>
                {termsTxt.split('\n')
                    .map((line, index) => {
                        return (
                            <p key={index}>{line}<br/></p>
                        )
                    })}
            </div>
        </NavMain>
    )
}

export default TermsPage;