import {FieldValidation} from "../protocols";
import {RequiredFieldError} from "../errors";
import {ObjType} from "../../model/object-model";


export class RequiredFieldValidation implements FieldValidation {
    constructor(readonly field: string) {
    }

    validate(input: ObjType): Error | null {
        return input[this.field] ? null : new RequiredFieldError()
    }
}
