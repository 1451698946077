import React from "react";
import {songUploadPolicyState} from "./atoms";
import {useRecoilState} from "recoil";
import {FormControlLabel, FormGroup} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";


const policyTxt = '인디스트림은 아래의 커뮤니티 가이드라인에 위배되는 모든 콘텐츠의 업로드를 금지합니다. 위반 시 인디스트림 서비스 이용에 제한이 있을 수 있으며 사전 통지 없이 제거 또는 이동할 권리를 보유합니다. 또한, 위반 수위에 따라 법적인 조처를 할 수 있습니다. 커뮤니티 가이드라인을 준수하지 않아 발생하는 인디스트림 서비스 이용 및 법적인 모든 피해는 회원의 책임이며, 인디스트림은 어떠한 책임도 지지 않습니다.\n' +
    '\n' +
    '0. 용어\n' +
    '\n' +
    '- 회원 : 본 커뮤니티 가이드라인에 동의하고 인디스트림이 제공하는 서비스의 이용 자격을 부여받은 자를 의미합니다.\n' +
    '- 콘텐츠 : 회원이 인디스트림 서버에 업로드한 모든 자료(글, 댓글, 파일, 썸네일, 채팅, 프로필 사진, 자기소개, 링크 등)를 의미합니다.\n' +
    '- 업로드 : 회원이 인디스트림 서비스에 콘텐츠를 표시하는 모든 행위를 의미합니다.\n' +
    '\n' +
    '1. 저작권\n' +
    '\n' +
    '- 인디스트림은 회원의 저작권을 존중하고 저작권법을 준수합니다.\n' +
    '- 저작권을 가지고 있지 않거나 위임 또는 합의되지 않은 콘텐츠 등 저작권법에 어긋나는 모든 콘텐츠에 대한 업로드를 금지합니다.\n' +
    '- 회원이 업로드한 적법한 콘텐츠의 저작권은 업로드한 회원에게 있습니다.\n' +
    '- 인디스트림은 회원이 업로드한 콘텐츠를 동의 없이 상업적으로 이용하지 않습니다.\n' +
    '\n' +
    '2. 성적인 콘텐츠\n' +
    '\n' +
    '- 인디스트림은 과도한 노출, 노골적인 요소, 수치심, 페티시즘 등 성적인 요소가 포함된 콘텐츠에 대한 업로드를 금지합니다.\n' +
    '- 인디스트림은 성적인 콘텐츠로 오해받을 수 있는 콘텐츠에 대한 업로드를 금지합니다.\n' +
    '- 인디스트림은 사회 통념상 성적인 요소로 해석될 수 있는 콘텐츠에 대한 업로드를 금지합니다.\n' +
    '\n' +
    '3. 폭력, 유해, 위험한 콘텐츠\n' +
    '\n' +
    '- 인디스트림은 폭력, 언어폭력, 유해성, 위험성 등을 유발, 연상, 포함하는 콘텐츠에 대한 업로드를 금지합니다.\n' +
    '- 인디스트림은 3항에 해당하는 오해를 불러일으키거나 사회통념 또는 법적으로 어긋나거나 그러한 해석의 여지가 있는 콘텐츠에 대한 업로드를 금지합니다.\n' +
    '- 인디스트림은 인디스트림 서비스 전반에 위험한 행위를 일으키거나 가능성이 있는 모든 콘텐츠를 금지합니다.\n' +
    '\n' +
    '4. 혐오, 증오성 콘텐츠\n' +
    '\n' +
    '- 인디스트림은 혐오, 증오가 포함 또는 유사한 콘텐츠에 대한 업로드를 금지합니다.\n' +
    '- 인디스트림은 4항에 해당하는 콘텐츠로 인해 회원 간의 갈등을 유발 할 수 있는 모든 콘텐츠를 금지합니다.\n' +
    '\n' +
    '5. 스팸, 현혹행위, 사기, 거짓 정보 유포, 잘못된 메타데이터 등에 대한 콘텐츠\n' +
    '\n' +
    '- 인디스트림은 서비스 내 스팸성 콘텐츠의 업로드를 금지합니다.\n' +
    '- 인디스트림은 인디스트림 이용자들을 대상으로 하는 현혹 행위와 사기행위 또는 그에 준하는 모든 행위를 금지합니다.\n' +
    '- 인디스트림은 서비스 내 거짓 정보를 유포하는 행위 및 콘텐츠를 금지합니다.\n' +
    '- 인디스트림은 콘텐츠의 내용과 일치하지 않는 허위성 메타데이터 또는 그에 따르는 데이터의 업로드를 금지합니다.\n' +
    '\n' +
    '6. 개인정보 및 명의도용 콘텐츠\n' +
    '\n' +
    '- 인디스트림은 동의하지 않은 개인정보가 포함된 모든 콘텐츠에 대한 업로드를 금지합니다.\n' +
    '- 인디스트림은 동의하지 않은 개인정보를 연상하거나 유사한 콘텐츠에 대한 업로드를 금지합니다.\n' +
    '- 인디스트림은 서비스 내 명의도용과 관련된 모든 업로드를 금지합니다.\n' +
    '\n' +
    '7. 아동·청소년 관련 콘텐츠\n' +
    '\n' +
    '- 인디스트림은 아동·청소년 보호를 위해 최선을 다합니다.\n' +
    '- 인디스트림은 인디스트림 커뮤니티 가이드라인 내 모든 위반사항에 대하여 아동·청소년이 관여되어있는 것을 금지합니다.\n' +
    '- 인디스트림은 아동·청소년에게 부정적인 영향을 미치는 모든 콘텐츠의 업로드를 금지합니다.\n' +
    '\n' +
    '8. 기타 법·사회통념에 위배되는 콘텐츠\n' +
    '\n' +
    '- 인디스트림은 인디스트림 커뮤니티 가이드라인에 명시된 행위 외 법·사회통념에 위배되는 모든 콘텐츠에 대한 업로드를 금지합니다.\n' +
    '\n' +
    '9. 신고 및 허위신고\n' +
    '\n' +
    '- 회원은 해당 인디스트림 커뮤니티 가이드라인에 위배되는 행위에 대하여 신고할 수 있습니다.\n' +
    '- 회원은 신고 서비스 이용 시 정당한 신고를 해야 할 의무가 있습니다.\n' +
    '- 인디스트림은 회원의 정당하지 않은 신고에 대하여 서비스 이용 제한 또는 법적인 조처를 할 수 있습니다.\n' +
    '\n' +
    '10. 커뮤니티 가이드라인에 대한 제안\n' +
    '\n' +
    '- 회원은 더 나은 가이드라인을 위해 문의 서비스로 가이드라인에 대한 의견을 제시할 수 있습니다.\n' +
    '\n' +
    '11. 처리와 책임\n' +
    '\n' +
    '- 인디스트림은 커뮤니티 가이드라인을 준수합니다.\n' +
    '- 인디스트림은 수시 모니터링과 신고 서비스를 통해 깨끗한 서비스 유지를 위해 최선을 다합니다.\n' +
    '- 인디스트림은 커뮤니티 가이드라인에 변경사항이 있을 경우 홈페이지 내 공지사항을 통해 변경사항을 고지합니다.\n' +
    '- 인디스트림은 커뮤니티 가이드라인을 지키지 않는 모든 콘텐츠를 회원의 동의 없이 이동, 제거할 권리를 보유합니다.\n' +
    '- 인디스트림은 커뮤니티 가이드라인 미준수에 대해 법적인 조처를 할 수 있습니다.\n' +
    '- 회원은 커뮤니티 가이드라인 미준수에 대한 모든 책임을 집니다.\n' +
    '- 인디스트림은 커뮤니티 가이드라인 미준수로 인한 인디스트림 서비스 이용 및 법적 모든 피해에 대해 책임지지 않습니다.\n' +
    '\n' +
    '\n' +
    '12. 적용일자\n' +
    '- 이 약관은 2022년 12월 1일부터 적용됩니다.'

const UploadSongPolicyStep: React.FC = () => {

    const [policyState, setPolicyState] = useRecoilState(songUploadPolicyState);

    const handleChangePolicy = (checked: boolean) => {
        setPolicyState(old => ({...old, policy: checked}));
    }

    return (
        <div className={'upload_song_policy_wrapper'}>
            <div>
                <h4>
                    음악 업로드관련 약관 이용동의
                </h4>
                <div className={'policy_box'}>
                    {policyTxt.split('\n')
                        .map((line, index) => {
                            return (
                                <p key={index}>{line}<br/></p>
                            )
                        })}
                </div>

                <FormGroup>
                    <FormControlLabel control={<Checkbox onChange={e => handleChangePolicy(e.target.checked)}/>}
                                      label="음악 업로드 및 이용약관 동의"/>
                </FormGroup>
            </div>
        </div>
    )
}


export default UploadSongPolicyStep;