import React, {KeyboardEvent} from "react"
import AuthBackground from "../AuthBackground"
import Input from "./Input"
import {Link, useNavigate} from "react-router-dom"
import {useRecoilState, useRecoilValue} from "recoil"
import {loginFormState} from "./atoms"
import {ErrorResponse} from "../../../helpers/error"
import axios from 'axios'
import {BaseResponse} from "../../../model/api/BaseResponse";
import {uuidv4} from "../../../helpers/util";
import googleLoginImg from '../../../assets/images/sns/btn_google_signin_dark_focus_web.png';

const LoginPage: React.FC = () => {

    const state = useRecoilValue(loginFormState)
    const navigator = useNavigate()

    const handleLogin = async () => {
        try {
            const {data} = await axios.post('/auth/local', state, {})
            const loginResult = data as BaseResponse

            if (loginResult.success) {
                localStorage.setItem('_uid', uuidv4())
                localStorage.setItem('_indieTk', loginResult.data.token)
                navigator('/')
            }
        } catch (err: any) {
            ErrorResponse(err)
        }
    }

    const handleOnKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };

    const handleGoogleLogin = async () => {
        try {
            window.open('http://api.indiestream.kr/api/auth/google')
        } catch (err: any) {
            ErrorResponse(err)
        }
    }

    return (
        <AuthBackground>
            <div className={'auth_title_wrapper'}>
                <h2>로그인</h2>
            </div>
            <div className={'login_page_wrapper'}>
                <div className={'login_form_wrapper'}>
                    <Input type={'email'} name={'email'} placeholder={'이메일'}/>
                    <Input onKeyPress={handleOnKeyPress} type={'password'} name={'password'} placeholder={'비밀번호'}/>
                </div>
                <div className={'login_btn_wrapper'}>
                    <button onClick={handleLogin}>로그인</button>
                </div>
                <div className={'google_btn_wrapper'}>
                    <button onClick={handleGoogleLogin}><img src={googleLoginImg} alt={'google_btn'}/></button>
                </div>
            </div>
            <ul className={'auth_find_wrapper'}>
                <li>
                    <Link to={'/'}>
                        비밀번호 찾기
                    </Link>
                </li>
                <li>
                    <Link to={'/'}>
                        아이디 찾기
                    </Link>
                </li>
                <li>
                    <Link to={'/register'}>
                        회원가입
                    </Link>
                </li>
            </ul>
        </AuthBackground>
    )
}


export default LoginPage