import React from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import Quill from "quill";

const modules = {
    toolbar: [
        [{header: [1, 2, 3, 4, 5, 6, false]}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
        [{color: []}, {background: []}, {align: []}],
        [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}, {font: []}],
    ],
    clipboard: {matchVisual: false}
};


const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'color',
    'background',
    'code-block',
    'align'
];

const getContent = (value: string) => (value === '<p><br></p>' ? '' : value);

interface IProps {

    value: string
    onChange: (value: string) => void
}

const NoticeQuillEditor: React.FC<IProps> = ({value, onChange, ...rest}) => {
    return (
        <>
            <ReactQuill
                style={{height: 400, marginBottom: 44}}
                value={getContent(value)} onChange={value => onChange(value)} {...rest} />
        </>
    )
}

NoticeQuillEditor.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

// @ts-ignore
NoticeQuillEditor.defaultProps = {modules, formats};

export default NoticeQuillEditor;
