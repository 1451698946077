import {atom, selector} from 'recoil'
import coverDefaultImg from '../../../assets/images/cover/small/1.jpg'
import {Album} from "../../../model/api/Album";
import {Music} from "../../../model/api/Music";
import {JudgeMusic} from "../../admin/atoms";
import {isIterableArray, uuidv4} from "../../../helpers/util";

interface Form {
    [index: string]: any

    album_no?: number
    title: string
    lyricist: string
    songwriter: string
    arranger: string
    genre: string
    mood: string
    participation: string
    description: string
    lyrics: string
    tags: string[]
    coverImage: File
    song: File

}


export const songUploadFormState = atom<Form>({
    key: 'songUploadFormState',
    default: {
        coverImage: coverDefaultImg,
        song: coverDefaultImg,
        title: '',
        songwriter: '',
        lyricist: '',
        genre: 'Acoustic',
        participation: '',
        description: '',
        lyrics: '',
        mood: '신남',
        arranger: '',
        tags: ['']
    }
})

interface IPolicy {
    policy: boolean
}

export const songUploadPolicyState = atom<IPolicy>({
    key: 'songUploadPolicyState',
    default: {
        policy: false
    }
})


interface IMyStudio {
    no: number
    email: string
    phone: string
    nickname: string
    type: string
    profile_img?: string
    isMine: boolean
    albums: Album[]
    musics: Music[]
    follower_cnt: number
    following_cnt: number
    isFollower: boolean
    judges: JudgeMusic[]
}

export const myStudioState = atom<IMyStudio>({
    key: 'myStudioState',
    default: {
        no: 0,
        email: '',
        phone: '',
        nickname: '',
        type: '',
        isMine: false,
        albums: [],
        musics: [],
        follower_cnt: 0,
        following_cnt: 0,
        isFollower: false,
        judges: []
    }
})


interface IStatus {
    tab: number
    showCreateMusicDialog: boolean
}


export const myStudioStatusState = atom<IStatus>({
    key: 'myStudioStatusState',
    default: {
        tab: 0,
        showCreateMusicDialog: false
    }
})

interface IJudgeList {
    [index: string]: Music[]

    wait: Music[]
    fail: Music[]
    pass: Music[]
}

export const judgeMusicState = selector<IJudgeList>({
    key: `judgeMusicState/${uuidv4()}`,
    get: ({get}) => {
        const list = get(myStudioState);

        let waitJudgeMusics: Music[] = []
        let failJudgeMusics: Music[] = []
        let passJudgeMusics: Music[] = []

        if (!isIterableArray(list.judges)) {
            return {
                wait: [],
                fail: [],
                pass: []
            }
        }
        list.judges.filter(m => !m.pass && m.comment.length === 0).forEach(judgeMusic => {
            const index = list.musics.findIndex(m => m.no === judgeMusic.music_no)
            if (index >= 0) {
                waitJudgeMusics.push(list.musics[index])
            }
        })

        list.judges.filter(m => !m.pass && m.comment.length !== 0).forEach(judgeMusic => {
            const index = list.musics.findIndex(m => m.no === judgeMusic.music_no)
            if (index >= 0) {
                failJudgeMusics.push(list.musics[index])
            }
        })

        list.judges.filter(m => m.pass).forEach(judgeMusic => {
            const index = list.musics.findIndex(m => m.no === judgeMusic.music_no)
            if (index >= 0) {
                passJudgeMusics.push(list.musics[index])
            }
        })

        return {
            wait: waitJudgeMusics,
            fail: failJudgeMusics,
            pass: passJudgeMusics
        }
    },
});

