import React, {SyntheticEvent} from "react"
import releaseImg from '../../assets/images/cover/large/5.jpg'
import {Music} from "../../model/api/Music";
import {useNavigate} from "react-router-dom";


interface IProps {
    music: Music
}

const NewReleaseChartItem: React.FC<IProps> = ({music}) => {
    const navigator = useNavigate()

    const handleImgError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
        e.currentTarget.src = releaseImg
    }
    return (
        <div className={'new_release_chart_item_wrapper'}
             onClick={() => navigator(`/song/detail/${music.no}`)}>
            <img src={music.cover_img ? music.cover_img : releaseImg}
                 alt={'release_chart_image'}
                 onError={handleImgError}/>
            <div className={'song_info'}>
                <span>{music.title}</span>
                <span>{music.nickname}</span>
            </div>
        </div>
    )
}

export default NewReleaseChartItem