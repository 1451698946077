import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import SongStudioTab from "./song/SongStudioTab";
import ProfileStudioTab from "./profile/ProfileStudioTab";
import AlbumStudioTab from "./album/AlbumStudioTab";
import {useRecoilState} from "recoil";
import {myStudioStatusState} from "./song/atoms";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`studio-tabpanel-${index}`}
            aria-labelledby={`studio-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div style={{padding: '1rem'}}>
                    {children}
                </div>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `studio-tab-${index}`,
        'aria-controls': `studio-tabpanel-${index}`,
    };
}

interface IProps {
    handleUpdateProfile: (type: string, value: any) => void
}

const MyStudioTabs: React.FC<IProps> = ({handleUpdateProfile}) => {
    const [statusState, setStatusState] = useRecoilState(myStudioStatusState);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setStatusState(old => ({...old, tab: newValue,}))
    };

    return (
        <div className={'my_studio_tabs_wrapper'}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={statusState.tab} onChange={handleChange} aria-label="user studio tabs">
                    <Tab label="앨범" {...a11yProps(0)} />
                    <Tab label="곡" {...a11yProps(1)} />
                    <Tab label="프로필" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={statusState.tab} index={0}>
                <AlbumStudioTab/>
            </TabPanel>
            <TabPanel value={statusState.tab} index={1}>
                <SongStudioTab/>
            </TabPanel>
            <TabPanel value={statusState.tab} index={2}>
                <ProfileStudioTab handleUpdateProfile={handleUpdateProfile}/>
            </TabPanel>
        </div>
    );
}

export default MyStudioTabs