import React, {ReactNode} from "react"
import LeftSideNavbar from "./LeftSideNavbar"
import AudioPlayer from "../player/AudioPlayer"
import bannerImg from '../../assets/images/banner/home.jpg'
import NavPageTopBar from "./NavPageTopBar";
import MusicPlayer from "../player/MusicPlayer";

interface IProps {
    children: ReactNode,
    pageTitle: string
}

const NavMain: React.FC<IProps> = ({children, pageTitle}) => {

    return (
        <div className={'nav_main_wrapper'}>
            <LeftSideNavbar/>
            <div className={'nav_page_wrapper'}>
                <NavPageTopBar/>
                <div
                    style={{backgroundImage: `url(${bannerImg})`}}
                    className={'page_banner'}>

                </div>
                <div style={{paddingBottom: '120px'}} className={'container'}>
                    <h1 className={'page_title'}>{pageTitle}</h1>
                    {children}
                </div>
                {/*<AudioPlayer/>*/}
            </div>
        </div>
    )
}


export default NavMain