type Genre = {
    name: string
}


export const genres: Genre[] = [
    {
        name: 'Acoustic'
    },
    {
        name: 'Ballad'
    },
    {
        name: 'Beat'
    },
    {
        name: 'Dance'
    },
    {
        name: 'EDM'
    },
    {
        name: 'Hiphop'
    },
    {
        name: 'Indie'
    },
    {
        name: 'Jazz'
    },
    {
        name: 'Rock & Metal'
    },
    {
        name: 'OST'
    },
    {
        name: 'Pop'
    },
    {
        name: 'R&B & Soul'
    },
]
