import React, {ReactNode} from "react"
import logoImg from '../../assets/images/logos/logo.svg'

interface IProps {
    children: ReactNode
}

const AuthBackground: React.FC<IProps> = ({children}) => {


    return (
        <div className={'auth_background_wrapper'}>
            <div className={'auth_content_wrapper'}>
                <div className={'logo_wrapper'}>
                    <img src={logoImg} alt={'logo_image'}/>
                </div>
                {children}
            </div>
        </div>
    )
}

export default AuthBackground