import React, {useState} from "react"
import Modal from '@mui/material/Modal'
import Input from "./Input"
import ImageUploader from "react-images-upload"
import {genres} from "../../genreChart/Constant";
import classNames from "classnames";
import {ErrorResponse} from "../../../helpers/error";
import {isIterableArray} from "../../../helpers/util";
import {notificationAlert} from "../../../helpers/alert";
import {useRecoilState} from "recoil";
import {createAlbumFormState} from "./atoms";
import axios from "axios";
import {BaseResponse} from "../../../model/api/BaseResponse";


interface IProps {
    handleClose: () => void
    open: boolean
}

const CreateAlbumDialog: React.FC<IProps> = ({handleClose, open}) => {

    const [previewImg, setPreviewImg] = useState<string[]>([])
    const [state, setState] = useRecoilState(createAlbumFormState)
    const handleChangeCoverImg = (files: File[], pictures: string[]) => {
        setState(old => ({...old, images: files}))
        setPreviewImg(pictures)
    }

    const handleSelectGenre = (genre: string) => {
        if (state.genre.findIndex(gr => gr === genre) < 0) {
            setState(old => ({...old, genre: [...old.genre, genre]}))
        } else {
            setState(old => ({...old, genre: [...old.genre.filter(gr => gr !== genre)]}))

        }
    }

    const handleCreateAlbum = async () => {
        try {
            if (!isIterableArray(state.images)) {
                notificationAlert('알림', '커버 이미지를 등록하여 주세요.')
                return
            }
            if (!isIterableArray(state.genre)) {
                notificationAlert('알림', '장르를 최소 1개이상 선택하여 주세요.')
                return
            }
            if (state.title.length === 0) {
                notificationAlert('알림', '앨범 제목을 입력하여 주세요.')
                return
            }
            const formData = new FormData();

            for (let imgFile of state.images) {
                formData.append('images', imgFile);
            }

            formData.append(
                "genre",
                state.genre.toString()
            );

            formData.append(
                "title",
                state.title
            )

            const {data} = await axios.post('/album', formData)
            const result = data as BaseResponse


            if (result.success) {
                notificationAlert('알림', '앨범이 생성되었습니다.');
                handleClose();
                window.location.reload();
            }

        } catch (err) {
            ErrorResponse(err)
        }
    }

    return (
        <Modal
            className={'dialog'}
            open={open}
            onClose={handleClose}
        >
            <div className={'create_album_dialog_wrapper'}>
                <div className={'dialog_wrapper'}>
                    <div className={'dialog_xl dialog_background'}>
                        <div className={'dialog_title'}>
                            <h3>앨범 생성</h3>
                        </div>
                        <div className={'dialog_content'}>
                            <div className={'preview_section'}>
                                {previewImg.map((img, index) => <img key={index} alt={'preview_img'} src={img}/>)}
                            </div>
                            <ImageUploader
                                withIcon={false}
                                withPreview={false}
                                label=""
                                buttonText={previewImg.length === 0 ? "커버 이미지 등록" : "커버 이미지 변경"}
                                onChange={handleChangeCoverImg}
                                imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg"]}
                                maxFileSize={1048576}
                                fileSizeError="이미지 크기는 10MB 이하만 가능합니다."
                                singleImage
                            />
                            <Input type={'text'} name={'title'} label={'앨범 제목'} placeholder={'앨범 제목을 입력하여 주세요.'}/>
                            <div className={'select_genre_wrapper'}>
                                <label>앨범 장르</label>
                                <div className={'chip_list'}>
                                    {genres.map((genre, index) => {
                                        return (
                                            <button
                                                onClick={() => handleSelectGenre(genre.name)}
                                                key={index}
                                                className={classNames({
                                                    'genre_chip': true,
                                                    'select': state.genre.some(gr => gr === genre.name)
                                                })}>
                                                {genre.name}
                                            </button>
                                        )
                                    })}
                                </div>
                            </div>

                            <button className={'sub_btn'}
                                    onClick={handleCreateAlbum}>
                                앨범 만들기
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>

    )
}


export default CreateAlbumDialog