import React from "react"
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import {RecoilRoot, useRecoilState} from 'recoil'
import LandingPage from "../components/landing/LandingPage";
import TopChartPage from "../components/topChart/TopChartPage";
import LoginPage from "../components/auth/login/LoginPage";
import RegisterPage from "../components/auth/register/RegisterPage";
import AlbumDetailPage from "../components/album/detail/AlbumDetailPage";
import SongDetailPage from "../components/song/detail/SongDetailPage";
import GenreChartPage from "../components/genreChart/GenreChartPage";
import NewChartPage from "../components/newChart/NewChartPage";
import MyStudioPage from "../components/myStudio/MyStudioPage";
import NoticePage from "../components/notice/NoticePage";
import WriteNoticePage from "../components/notice/WriteNoticePage";
import NoticeDetailPage from "../components/notice/NoticeDetailPage";
import JudgeMusicPage from "../components/admin/JudgeMusicPage";
import MusicPlayer from "../components/player/MusicPlayer";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import {musicPlayerListState} from "../components/player/atoms";
import PlayListDrawer from "../components/player/PlayListDrawer";
import TermsPage from "../components/policy/TermsPage";
import PrivacyPage from "../components/policy/PrivacyPage";

const PlayerToogleBtn: React.FC = () => {
    const [playerState, setPlayerState] = useRecoilState(musicPlayerListState)

    const handleTogglePlayer = () => {
        setPlayerState(old => ({...old, open: !old.open}))
    }

    return (
        <button id={'play_list_toggle_btn'}
                onClick={handleTogglePlayer}>
            <FormatListBulletedIcon/>
        </button>
    )
}

const Router: React.FC = () => {


    return (
        <RecoilRoot>
            <BrowserRouter>
                <Routes>
                    <Route path={'/'} element={<LandingPage/>}/>
                    <Route path={'/login'} element={<LoginPage/>}/>
                    <Route path={'/register'} element={<RegisterPage/>}/>
                    <Route path={"/topChart"} element={<TopChartPage/>}/>
                    <Route path={"/genre"} element={<GenreChartPage/>}/>
                    <Route path={"/newMusic"} element={<NewChartPage/>}/>
                    <Route path={"/privacy"} element={<PrivacyPage/>}/>
                    <Route path={"/terms"} element={<TermsPage/>}/>
                    <Route path={"/admin"}>
                        <Route path={'Judge'} element={<JudgeMusicPage/>}/>
                    </Route>
                    <Route path={"/notice"}>
                        <Route path={''} element={<NoticePage/>}/>
                        <Route path={"detail/:no"} element={<NoticeDetailPage/>}/>
                        <Route path={'writeNotice'} element={<WriteNoticePage/>}/>
                    </Route>
                    <Route path="/album">
                        <Route path={"detail/:no"} element={<AlbumDetailPage/>}/>
                    </Route>
                    <Route path="/song">
                        <Route path={"detail/:no"} element={<SongDetailPage/>}/>
                    </Route>
                    <Route path={"/myStudio"}>
                        <Route path={""} element={<MyStudioPage/>}/>
                    </Route>
                </Routes>

            </BrowserRouter>
            {(window.location.pathname !== '/login') && (window.location.pathname !== '/register') &&
                <>
                    <PlayerToogleBtn/>
                    <MusicPlayer/>
                    <PlayListDrawer/>
                </>
            }
        </RecoilRoot>
    )
}

export default Router