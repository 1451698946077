import React, {useEffect, useState} from "react"
import NavMain from "../nav/NavMain";
import TopMusicChart from "../landing/musicChart/TopMusicChart";
import {Music} from "../../model/api/Music";
import axios from "axios";
import {BaseResponse} from "../../model/api/BaseResponse";
import {ErrorResponse} from "../../helpers/error";


const TopChartPage: React.FC = () => {

    const [topMusics, setTopMusics] = useState<Music[]>([])


    const getTopChart = async () => {
        try {
            const {data} = await axios.get(`/music/chart?type=realtime`)
            const result = data as BaseResponse

            if (result.success) {

                setTopMusics(result.data)
            }

        } catch (err: any) {
            ErrorResponse(err)
        }
    }

    useEffect(() => {
        getTopChart()
    }, [])

    return (
        <NavMain pageTitle={'실시간 차트'}>
            <div className={'top_chart_wrapper'}>
                <TopMusicChart musics={topMusics} useRankCompare={true}/>
            </div>
        </NavMain>
    )
}


export default TopChartPage