import React, {useEffect, useState} from "react"
import NavMain from "../nav/NavMain";
import TopMusicChart from "../landing/musicChart/TopMusicChart";
import {Music} from "../../model/api/Music";
import axios from "axios";
import {BaseResponse} from "../../model/api/BaseResponse";
import {ErrorResponse} from "../../helpers/error";


const NewChartPage: React.FC = () => {
    const [newMusics, setNewMusics] = useState<Music[]>([])


    const getNewChart = async () => {
        try {
            const {data} = await axios.get(`/music/chart?type=new`)
            const result = data as BaseResponse

            if (result.success) {
                setNewMusics(result.data)
            }

        } catch (err: any) {
            ErrorResponse(err)
        }
    }

    useEffect(() => {
        getNewChart()
    }, [])

    return (
        <NavMain pageTitle={'최신음악'}>
            <div className={'new_chart_wrapper'}>
                <TopMusicChart musics={newMusics} hideRank={true}/>
            </div>
        </NavMain>
    )
}

export default NewChartPage