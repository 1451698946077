import React, {useState} from "react"
import UploadSongTable from "./UploadSongTable";
import UploadSongDialog from "./UploadSongDialog";
import {myStudioStatusState} from "./atoms";
import {useRecoilState} from "recoil";


const SongStudioTab: React.FC = () => {

    const [status, setState] = useState<string>('pass');
    const [statusState, setStatusState] = useRecoilState(myStudioStatusState);
    const handleClose = () => {
        setStatusState(old => ({...old, showCreateMusicDialog: !old.showCreateMusicDialog}));
    }


    return (
        <div className={'song_upload_wrapper'}>
            <div className={'upload_btn_wrapper'}>
                <div className={'song_status_header'}>
                    <select value={status} onChange={event => setState(event.target.value)}>
                        <optgroup>
                            <option value={'pass'} style={{fontSize: '10px'}}>심사 완료된 곡</option>
                            <option value={'wait'}>심사 대기중인 곡</option>
                            <option value={'fail'}>심사 불합격한 곡</option>
                        </optgroup>
                    </select>
                </div>
                <button onClick={handleClose}>음악 업로드</button>
            </div>

            <UploadSongTable status={status}/>
            <UploadSongDialog open={statusState.showCreateMusicDialog} handleClose={handleClose}/>
        </div>
    )
}


export default SongStudioTab