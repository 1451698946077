import React, {useRef} from "react"
import {useRecoilState} from "recoil";
import {songUploadFormState} from "./atoms";
import {notificationAlert} from "../../../helpers/alert";


const UploadFileStep: React.FC = () => {

    const [state, setState] = useRecoilState(songUploadFormState)
    const musicRef = useRef<HTMLInputElement>(null)
    const coverImgRef = useRef<HTMLInputElement>(null)


    const handleUpdateMusic = async (musicList: FileList | null) => {
        if (musicList) {
            const songFile = musicList[0]

            if (songFile.size > 1024 * 1024 * 10) {
                notificationAlert('알림', '10MB 이하 파일만 등록할 수 있습니다.\n\n' + '현재파일 용량 : ' + (Math.round(songFile.size / 1024 / 1024 * 100) / 100) + 'MB');
                return;
            }
            setState(old => ({...old, song: songFile}))
        }
    }

    const handleUpdateCoverImg = async (imgList: FileList | null) => {
        if (imgList) {
            const imgFile = imgList[0]

            if (imgFile.size > 1024 * 1024 * 3) {
                notificationAlert('알림', '3MB 이하 파일만 등록할 수 있습니다.\n\n' + '현재파일 용량 : ' + (Math.round(imgFile.size / 1024 / 1024 * 100) / 100) + 'MB');
                return;
            }

            setState(old => ({...old, coverImage: imgFile}))
        }
    }

    return (
        <div className={'upload_file_step_wrapper'}>
            <div className={'upload_form_wrapper'}>
                <label>음악 업로드 (mp3 확장자만 가능하며, 최대 5MB까지 업로드 가능합니다.)</label>
                <input
                    ref={musicRef}
                    type="file"
                    id="upload_music_input"
                    accept=".mp3"
                    onChange={(e) => handleUpdateMusic(e.target.files)}
                />
                <div className={'upload_form'}>
                    <input type={'text'} value={state.song.name} disabled/>
                    <button onClick={() => musicRef.current?.click()}>음악 업로드</button>
                </div>
            </div>
            <div className={'upload_form_wrapper'}>
                <label>커버 이미지 업로드 (최대 3MB까지 업로드 가능합니다.)</label>
                <input
                    ref={coverImgRef}
                    type="file"
                    id="upload_cover_input"
                    accept="image/jpg, image/png, image/jpeg"
                    onChange={(e) => handleUpdateCoverImg(e.target.files)}
                />
                <div className={'upload_form'}>
                    <input type={'text'} value={state.coverImage.name} disabled/>
                    <button onClick={() => coverImgRef.current?.click()}>커버 이미지 업로드</button>
                </div>
            </div>
        </div>
    )
}

export default UploadFileStep