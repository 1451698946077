import {FieldValidation} from "../validation/protocols";
import {ObjType} from "../model/object-model";

interface Validation {
}

export class ValidationComposite implements Validation {
    private constructor(private readonly validators: FieldValidation[]) {
    }

    static build(validators: FieldValidation[]): ValidationComposite {
        return new ValidationComposite(validators)
    }

    validate(fieldName: string, input: ObjType): string {
        const validators = this.validators.filter(v => v.field === fieldName)
        for (const validator of validators) {
            const error = validator.validate(input)
            if (error) {
                return error.message
            }
        }
        return ''
    }
}
