import {SyntheticEvent, useEffect, useState} from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import baseCoverImg from '../../assets/images/cover/small/3.jpg'
import {useRecoilState} from "recoil";
import {musicPlayerListState} from "./atoms";
import {useLocation} from "react-router-dom";
import {isIterableArray} from "../../helpers/util";

export default function MusicPlayer() {
    const [state, setState] = useRecoilState(musicPlayerListState)
    const [trackIndex, setTrackIndex] = useState(0);

    const handleClickPrevious = () => {
        const musicIndex = state.players.findIndex(m => m.musicNo === state.nowPlaying?.musicNo)
        if (musicIndex >= 0) {
            if (musicIndex === 0) {
                setState(old => ({...old, nowPlaying: state.players[state.players.length - 1]}))
            } else {
                setState(old => ({...old, nowPlaying: state.players[musicIndex - 1]}))
            }
        }
    };

    const handleClickNext = () => {
        const musicIndex = state.players.findIndex(m => m.musicNo === state.nowPlaying?.musicNo)
        if (musicIndex >= 0) {
            if (state.players.length === (musicIndex + 1)) {
                setState(old => ({...old, nowPlaying: state.players[0]}))
            } else {
                setState(old => ({...old, nowPlaying: state.players[musicIndex + 1]}))
            }
        }

    };

    useEffect(() => {
        if (isIterableArray(state.players)) {
            const playMusic = state.players[0];
            setState(old => ({...old, nowPlaying: playMusic}));
        }
    }, [state.players])

    const handleImgError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
        e.currentTarget.src = baseCoverImg
    }

    return (
        <div className={'music_player_wrapper'}>
            <div className={'music_info_section'}>
                <div className={'music_cover'}>
                    <img src={state.nowPlaying?.coverImg ? state.nowPlaying.coverImg : baseCoverImg}
                         alt={'player_music_cover'}
                         onError={handleImgError}/>
                </div>
                <div className={'music_txt'}>
                    <p>{state.nowPlaying?.title}</p>
                    <small>{state.nowPlaying?.artist}</small>
                </div>
            </div>
            <div id={'audio_player'}>
                <AudioPlayer
                    src={state.nowPlaying?.file}
                    onPlay={(e) => console.log("onPlay")}
                    showSkipControls={true}
                    showJumpControls={false}
                    onClickPrevious={handleClickPrevious}
                    onClickNext={handleClickNext}
                    onEnded={handleClickNext}
                />
            </div>
        </div>
    );
}
