import React, {useEffect, useState} from "react"
import SmallChartItem from "./SmallChartItem";
import {useNavigate} from "react-router-dom";
import {Music} from "../../../model/api/Music";
import axios from "axios";
import {BaseResponse} from "../../../model/api/BaseResponse";
import {ErrorResponse} from "../../../helpers/error";


const SmallTopMusicChart: React.FC = () => {
    const navigator = useNavigate()
    const [topMusics, setTopMusics] = useState<Music[]>([])


    const getTopChart = async () => {
        try {
            const {data} = await axios.get(`/music/chart?type=realtime`)
            const result = data as BaseResponse

            if (result.success) {
                setTopMusics(result.data)
            }

        } catch (err: any) {
            ErrorResponse(err)
        }
    }

    useEffect(() => {
        getTopChart()
    }, [])

    return (
        <div className={'small_top_music_chart_wrapper'}>
            <div className={'chart_header'}>
                <h3 onClick={() => navigator('/topChart')}>TOP 차트</h3>
                <hr/>
            </div>
            <div className={'chart_list'}>
                {topMusics.slice(0, 14).map((music, index) => <SmallChartItem key={index} music={music}
                                                                              rank={index + 1}/>)}
            </div>
        </div>
    )
}

export default SmallTopMusicChart