import React, {useEffect, useRef, useState} from "react"
import NavMain from "../nav/NavMain"
import baseProfile from '../../assets/images/cover/large/3.jpg'
import MyStudioTabs from "./MyStudioTabs";
import axios from "axios";
import {BaseResponse} from "../../model/api/BaseResponse";
import {ErrorResponse} from "src/helpers/error";
import {useRecoilState, useRecoilValue} from "recoil";
import {profileState} from "../nav/atoms";
import {myStudioState, myStudioStatusState} from "./song/atoms";
import {notificationAlert} from "../../helpers/alert";
import {useSearchParams} from "react-router-dom";

const MyStudioPage: React.FC = () => {

    const myProfile = useRecoilValue(profileState);
    const [state, setState] = useRecoilState(myStudioState);
    const [statusState, setStatusState] = useRecoilState(myStudioStatusState);
    const [showProfileBtn, setShowProfileBtn] = useState<boolean>(false);
    const profileImgRef = useRef<HTMLInputElement>(null);

    const [searchParams] = useSearchParams();
    const qsType = searchParams.get('type');

    useEffect(() => {
        if (qsType) {
            if (qsType === 'upload') {
                setStatusState(old => ({...old, tab: 1, showCreateMusicDialog: true}))
            }
        }

    }, [qsType]);


    useEffect(() => {
        if (myProfile.no !== 0)
            handleGetStudioInfo()
    }, [myProfile.no])

    const handleGetStudioInfo = async () => {
        try {

            const {data} = await axios.get(`/user/${myProfile.no}`)
            const result = data as BaseResponse

            if (result.success) {
                setState(old => ({...old, ...result.data}))
            }

        } catch (err: any) {
            ErrorResponse(err);
        }
    }

    const handleUpdateProfile = async (type: string, value: any) => {
        try {
            const formData = new FormData()
            formData.append(type, value)

            const {data} = await axios.put('/user', formData)
            const result = data as BaseResponse

            if (result.success) {
                notificationAlert('알림', '프로필이 업데이트 되었습니다.');
                window.location.reload();
            }

        } catch (err: any) {
            ErrorResponse(err)
        }
    }

    const handleUpdateProfileImg = async (imgList: FileList | null) => {
        if (imgList) {
            const imgFile = imgList[0]

            if (imgFile.size > 1024 * 1024 * 3) {
                notificationAlert('알림', '3MB 이하 파일만 등록할 수 있습니다.\n\n' + '현재파일 용량 : ' + (Math.round(imgFile.size / 1024 / 1024 * 100) / 100) + 'MB');
                return;
            }

            await handleUpdateProfile('images', imgFile)
        }
    }

    const handleClickFollow = async () => {
        try {

            const {data} = await axios.post(`/user/${state.no}/follower`, {})
            const result = data as BaseResponse

            if (result) {
                notificationAlert('알림', state.isFollower ? '팔로우가 취소되었습니다.' : '팔로우가 반영되었습니다.')
                window.location.reload();
            }


        } catch (err: any) {
            ErrorResponse(err)
        }
    }

    return (
        <NavMain pageTitle={'마이 스튜디오'}>
            <div className={'my_studio_page_wrapper'}>
                <div className={'studio_profile_wrapper'}>
                    <div className={'profile_img_wrapper'}
                         onMouseEnter={() => setShowProfileBtn(true)}
                         onMouseLeave={() => setShowProfileBtn(false)}
                    >
                        <input
                            style={{display: "none"}}
                            ref={profileImgRef}
                            type="file"
                            id="profile_img_input"
                            accept="image/jpg, image/png, image/jpeg"
                            onChange={(e) => handleUpdateProfileImg(e.target.files)}
                        />
                        {showProfileBtn && <button onClick={() => profileImgRef.current?.click()}>프로필 사진 변경</button>}
                        {state.profile_img ?
                            <img src={state.profile_img} alt={'studio_profile'}/>
                            :
                            <img src={baseProfile} alt={'studio_profile'}/>
                        }
                    </div>
                    <div className={'profile_info'}>
                        <h2 id={'profile_name'}>{state.nickname}</h2>
                        <div className={'profile_follow'}>
                            <button className={'follow_btn'} onClick={handleClickFollow}>
                                {state.isFollower ? '팔로우 취소' : '팔로우'}
                            </button>
                            <div className={'follow'}>
                                <span className={'d-block'}>{state.following_cnt}</span>
                                <span>팔로잉</span>
                            </div>
                            <div className={'follower'}>
                                <span className={'d-block'}>{state.follower_cnt}</span>
                                <span>팔로워</span>
                            </div>
                        </div>
                    </div>
                </div>
                <MyStudioTabs handleUpdateProfile={handleUpdateProfile}/>

            </div>

        </NavMain>
    )
}

export default MyStudioPage