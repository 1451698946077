import React, {useEffect} from "react"
import NavMain from "../../nav/NavMain";
import albumThumbnail from '../../../assets/images/cover/large/5.jpg'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import AlbumMusicChart from "./AlbumMusicChart";
import {useParams} from "react-router-dom";
import {ErrorResponse} from "../../../helpers/error";
import axios from "axios";
import {BaseResponse} from "../../../model/api/BaseResponse";
import {useRecoilState, useRecoilValue} from "recoil";
import {albumDetailState} from "../atoms";
import dayjs from "dayjs";
import {profileState} from "../../nav/atoms";
import Swal from "sweetalert2";

const AlbumDetailPage: React.FC = () => {

    let {no} = useParams()
    const [state, setState] = useRecoilState(albumDetailState)
    const userProfile = useRecoilValue(profileState)
    useEffect(() => {
        handleGetAlbumInfo(no)
    }, [no])

    const handleGetAlbumInfo = async (albumNo: string | undefined) => {
        try {
            if (!albumNo) {
                return;
            }
            const {data} = await axios.get(`/album/${albumNo}`)
            const result = data as BaseResponse

            if (result.success) {
                setState(old => ({...old, album: {...result.data}}))
            }

        } catch (err) {
            ErrorResponse(err)
        }
    }

    const handleDeleteAlbum = (albumNo: number) => {
        Swal.fire({
            title: '정말 앨범을 삭제하시겠습니까?',
            text: '앨범 삭제시 수록된 모든 음악도 삭제 됩니다.',
            showCancelButton: true,
            cancelButtonText: '취소',
            confirmButtonText: '삭제',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                return await axios.delete(`/album/${albumNo}`)
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(result => {
            if (result) {
                if (result.isConfirmed && result.value) {
                    Swal.fire({
                        title: '앨범 삭제가 완료되었습니다.',
                        confirmButtonText: '닫기',
                        preConfirm: async () => {
                            return window.location.href = '/myStudio'
                        },
                        allowOutsideClick: false
                    })
                }
            }
        }).catch(err => {
            ErrorResponse(err)
        })

    }

    return (
        <NavMain pageTitle={'앨범 정보'}>
            <div className={'album_detail_wrapper'}>
                <div className={'detail_info'}>
                    <div className={'thumbnail'}>
                        <img src={albumThumbnail} alt={'thumbnail'}/>
                    </div>
                    <div className={'description'}>
                        <h2 className={'title'}>{state.album.album_title}</h2>
                        <p className={'artist'}>{state.album.album_title}</p>
                        <p className={'genre'}>
                            {state.album.genre.map((genre, index) => (state.album.genre.length === (index + 1)) ? genre : `${genre},`)}
                        </p>
                        <small>{dayjs(state.album.create_time).format('YYYY년 MM월 DD일')}</small>
                        <div className={'btn_wrapper'}>
                            <button className={'icon_btn'}><PlaylistAddIcon/></button>
                        </div>

                    </div>
                    {userProfile.no === state.album.artist_no &&
                        <div className={'delete_btn_wrapper'}>
                            <button onClick={() => handleDeleteAlbum(state.album.no)}>앨범 삭제</button>
                        </div>}
                </div>
                <AlbumMusicChart/>
                <div className={'introduce_album'}>
                    <h5 className={'title'}>앨범소개</h5>
                    <p>
                        "처음 네가 떠나가는<br/>
                        오늘 마지막이 될 기념일"<br/>
                        <br/>
                        추억을 '말하는' 벤<br/>
                        말하는 프로젝트 네 번째,<br/>
                        <br/>
                        [Credit]<br/>
                        Composed by : 한경수, 이도형(AUG)<br/>
                        Lyrics by : 한경수, 이도형(AUG)<br/>
                        Arranged by : 이도형(AUG), 임준식(AUG)<br/>
                        Piano : 이도형(AUG)<br/>
                        Guitar : 정수완<br/>
                        Bass : 이도형(AUG)<br/>
                        Drum : 이도형(AUG)<br/>
                        Strings : 융스트링<br/>
                        Strings Arrange & Conduct : 이도형(AUG)<br/>
                        Percussion : 한경수<br/>
                        <br/>
                        Recorded by 손아현 @ MAJOR9 Studio<br/>
                        Audio Edited by 김선아 @ MAJOR9 Studio<br/>
                        Mixed by 정기홍 @서울스튜디오<br/>
                        Mastered by 권남우 @ 821 Sound Mastering<br/>
                    </p>
                </div>
            </div>
        </NavMain>
    )
}

export default AlbumDetailPage