import React from "react"
import SmallTopMusicChart from "../../landing/musicChart/SmallTopMusicChart";
import TopMusicChart from "../../landing/musicChart/TopMusicChart";


const AlbumMusicChart: React.FC = () => {


    return (
        <div className={'album_music_chart_wrapper'}>
            <h5 className={'title'}>수록곡</h5>
            <TopMusicChart musics={[]}/>
        </div>
    )
}

export default AlbumMusicChart