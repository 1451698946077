import React from 'react'
import { useRecoilState } from 'recoil'
import {loginFormState} from "./atoms";
import {InputBase} from "../../common";

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    type: string
    name: string
    placeholder: string
}

const Input: React.FC<Props> = ({ type, name, placeholder, ...props }: Props) => {
    const [state, setState] = useRecoilState(loginFormState)

    return (
        <InputBase
            {...props}
            className={'auth_input'}
            type={type}
            name={name}
            placeholder={placeholder}
            state={state}
            setState={setState}
        />
    )
}

export default Input
