import React, {useEffect, useState} from "react"
import NavMain from "../nav/NavMain";
import {useParams} from "react-router-dom";
import ReactQuill from "react-quill";
import {ErrorResponse} from "../../helpers/error";
import axios from "axios";
import {BaseResponse} from "../../model/api/BaseResponse";
import dayjs from "dayjs";

interface INotice {
    no: number
    title: string
    content: string
    create_time: Date
}

const NoticeDetailPage: React.FC = () => {

    const {no} = useParams()
    const [notice, setNotice] = useState<INotice>({
        no: 0,
        title: 'wewewew',
        content: 'eweweww',
        create_time: new Date()
    })
    useEffect(() => {
        if (no) {
            getNoticeDetail(no)
        }
    }, [no])

    const getNoticeDetail = async (noticeNo: string) => {
        try {
            const {data} = await axios.get(`/noti/${noticeNo}`)
            const result = data as BaseResponse

            if (result.success) {
                setNotice(result.data)
            }

        } catch (err) {
            ErrorResponse(err)
        }
    }

    return (
        <NavMain pageTitle={'공지사항'}>
            <div className={'notice_detail_page_wrapper'}>
                <div className={'notice_time'}>
                    <small>{dayjs(notice.create_time).format('YYYY.MM.DD HH:mm:ss')}</small>
                </div>
                <div className={'notice_title'}>
                    <h3>{notice.title}</h3>
                </div>
                <hr/>
                <div className={'notice_content'}>
                    <ReactQuill
                        value={notice.content}
                        readOnly={true}
                        theme={"bubble"}/>
                </div>
            </div>
        </NavMain>
    )
}


export default NoticeDetailPage