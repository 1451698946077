import axios from "axios";
import {BaseResponse} from "../model/api/BaseResponse";
import {ErrorResponse} from "./error";


export const playMusicCount = async (musicNo: number) => {
    try {
        const {data} = await axios.post(`/music/${musicNo}/play`, {})
        const result = data as BaseResponse


    } catch (err: any) {
        ErrorResponse(err);
    }

}