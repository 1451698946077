import React from "react"
import {Col, Row} from "react-bootstrap";
import {useRecoilState} from "recoil";
import Input from "./Input";
import {ErrorResponse} from "../../../helpers/error";
import {notificationAlert} from "../../../helpers/alert";
import axios from "axios";
import {BaseResponse} from "../../../model/api/BaseResponse";


type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    state: any
    setState: any
}

const AuthPhoneForm: React.FC<Props> = ({state, setState}) => {


    const handleClickSendAuthNum = async () => {
        try {
            if (state.phone.length === 0) {
                notificationAlert('알림', '휴대폰 번호를 입력하여 주세요.')
                return
            }
            const authNum = String(Math.floor(Math.random() * 90000) + 10000)
            const {data} = await axios.post('/auth/confirm/phone', {
                phone: state.phone,
                code: authNum
            })
            const result = data as BaseResponse

            if (result.success) {
                notificationAlert('알림', '인증번호가 발송되었습니다.')
                setState((old: any) => ({...old, isAuthPhone: true, authNum}))
            }
        } catch (err: any) {
            ErrorResponse(err)
        }
    }

    const handleClickVerifyPhone = async () => {
        if (state.userAuthNum.length === 0) {
            notificationAlert('알림', '인증번호를 입력하여 주세요.')
            return
        }

        if (state.userAuthNum !== state.authNum) {
            notificationAlert('알림', '잘못된 인증번호 입니다.')
            return
        } else {
            setState((old: any) => ({...old, isAuthPhone: false, isSuccessAuthPhone: true}))
        }
    }

    return (
        <div className='auth_phone_wrapper'>
            <Row className='auth_phone_form_wrapper'>
                <Col className={'p-0'} xs={8}>
                    <Input label={'전화번호'} disabled={state.isSuccessAuthPhone} type="text" name="phone"
                           placeholder="전화번호"/>
                </Col>
                <Col className={'p-0'} style={{textAlign: 'right'}} xs={4}>
                    <button
                        type={'button'}
                        style={(state.isAuthPhone || state.isSuccessAuthPhone) ? {opacity: 0.5} : {opacity: 1}}
                        disabled={state.isAuthPhone || state.isSuccessAuthPhone}
                        onClick={handleClickSendAuthNum}>인증번호 받기
                    </button>
                </Col>
            </Row>
            {state.isSuccessAuthPhone && <p className={'success_auth_phone_txt'}>휴대폰 인증이 완료되었습니다.</p>}
            <Row className={state.isAuthPhone ? 'auth_num_form_wrapper show' : 'auth_num_form_wrapper'}>
                <Col className={'p-0'} xs={8}>
                    <Input type="text" name="userAuthNum" placeholder="인증번호" label={'인증번호'}/>
                </Col>
                <Col className={'p-0'} style={{textAlign: 'right'}} xs={4}>
                    <button type={'button'} onClick={handleClickVerifyPhone}>인증번호 등록</button>
                </Col>
            </Row>

        </div>
    )
}

export default AuthPhoneForm