import React, {useEffect} from "react"
import {Link, useNavigate} from "react-router-dom";
import {ErrorResponse} from "../../helpers/error";
import axios from "axios";
import {BaseResponse} from "../../model/api/BaseResponse";
import {useRecoilState} from "recoil";
import {noticeState} from "./atoms";
import dayjs from "dayjs";
import NavMain from "../nav/NavMain";

const NoticePage: React.FC = () => {

    const [state, setState] = useRecoilState(noticeState)
    const navigator = useNavigate()

    useEffect(() => {
        handleGetNotices()
    }, [])

    const handleGetNotices = async () => {
        try {
            const {data} = await axios.get('/noti')
            const result = data as BaseResponse

            if (result.success) {
                setState(old => ({...old, notices: result.data}))
            }
        } catch (err) {
            ErrorResponse(err)
        }
    }

    return (
        <NavMain pageTitle={'공지사항'}>
            <div className={'notice_page_wrapper'}>
                <table>
                    <thead>
                    <tr>
                        <th className="term_board_no">No</th>
                        <th className="term_board_title">제목</th>
                        <th className="term_board_time">등록일</th>
                    </tr>
                    </thead>
                    <tbody className="term_board_tbody">
                    {state.notices.map((notice, index) => {
                        return (
                            <tr key={index}
                                onClick={() => navigator(`/notice/detail/${notice.no}`)}
                            >
                                <th className="term_board_no" scope="row">{notice.no}</th>
                                <td className=" term_board_title" style={{textAlign: 'left'}}>
                                        {notice.title}
                                </td>
                                <td className="term_board_time">{dayjs(notice.create_time).format("YYYY-MM-DD")}</td>
                            </tr>
                        )
                    })}

                    </tbody>
                </table>
            </div>
        </NavMain>
    )
}


export default NoticePage