import {FieldValidation} from "../protocols";
import {ObjType} from "../../model/object-model";
import {InvalidFieldError} from "../errors";

export class MinLengthValidation implements FieldValidation {
    constructor(readonly field: string, private readonly minLength: number) {
    }

    validate(input: ObjType): Error | null {
        return input[this.field]?.length < this.minLength ? new InvalidFieldError('최소 몇글자 이상') : null
    }
}


